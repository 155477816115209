import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.card.status');
import { Input } from '@angular/core';

@Component({
    selector: 'wiz-component-card-status',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/component.card.status/view.scss */
.info-box {
  background: #fff;
  padding: 16px;
  font-size: 14px;
  margin: 24px 12px;
  border-radius: 14px;
  flex: auto;
}
.info-box table {
  margin-bottom: 8px;
  width: 100%;
}
.info-box table td {
  padding: 0;
  line-height: 1;
  white-space: nowrap;
}
.info-box table .title {
  font-weight: 800;
}
.info-box table .spare {
  border-bottom: 2px dotted rgba(116, 108, 112, 0.1);
}
.info-box.process-list {
  padding: 0;
  overflow: auto;
}`],
})
export class ComponentCardStatusComponent {
    @Input() title: string = '';
    @Input() items: any = [];

    constructor() {
    }

}

export default ComponentCardStatusComponent;