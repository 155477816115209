import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.auth.mypage');
import { OnInit, ChangeDetectorRef } from "@angular/core";
import { Service } from "src/libs/season/service";

import toastr from 'toastr';
toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": true,
    "progressBar": false,
    "positionClass": "toast-bottom-center",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": 300,
    "hideDuration": 500,
    "timeOut": 1500,
    "extendedTimeOut": 1000,
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

@Component({
    selector: 'wiz-page-auth-mypage',
template: templateSource || '',
    styles: [``],
})
export class PageAuthMypageComponent implements OnInit {

    public current: string = '';
    public password: string = '';
    public repeat_password: string = '';
    public checked: boolean = false;

    constructor(@Inject( Service)         public service: Service,@Inject( ChangeDetectorRef    )         public ref: ChangeDetectorRef    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/auth/login');
    }

    public async alert(message: string) {
        return await this.service.alert.show({
            title: "Error",
            message: message,
            cancel: false,
            action: "Confirm"
        });
    }

    public async update() {
        let user = {
            username: this.service.auth.username,
            email: this.service.auth.email,
            current: this.current,
            password: this.password,
            repeat_password: this.repeat_password
        }

        if (!this.checked) {
            let { code, data } = await wiz.call("check", user);
            if (code != 200) return await this.alert(data);
            this.checked = true;
            await this.service.render();
            return;
        }

        if (user.password.length == 0) {
            delete user.current;
            delete user.password;
            delete user.repeat_password;
        }

        if (user.password) {
            if (user.password.length < 8)
                return await this.alert('password must 8 characters or more');
            if (!user.repeat_password)
                return await this.alert('check password');
            if (user.password != user.repeat_password)
                return await this.alert('check password');
        }

        let { code, data } = await wiz.call("update", user);
        if (code != 200) return await this.alert(data);

        this.checked = false;
        this.current = '';
        this.password = '';
        this.repeat_password = '';

        toastr.success('updated');
        await this.service.render();
    }

}

export default PageAuthMypageComponent;