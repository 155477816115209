import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.analysis.request');
import { OnInit, ChangeDetectorRef } from "@angular/core";
import { Service } from "src/libs/season/service";

import moment from 'moment';
import toastr from 'toastr';
toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": true,
    "progressBar": false,
    "positionClass": "toast-bottom-center",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": 300,
    "hideDuration": 500,
    "timeOut": 1500,
    "extendedTimeOut": 1000,
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};


@Component({
    selector: 'wiz-page-analysis-request',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/page.analysis.request/view.scss */
.container {
  max-width: 720px;
}

.step-area {
  display: flex;
  align-items: center;
  justify-content: center;
}

textarea.form-control {
  height: auto;
  text-align: left;
  border: none;
  padding: 0;
  border-radius: 0;
  margin: 4px;
}
textarea.form-control:focus {
  box-shadow: none;
  outline: none;
}

.card {
  border-radius: 14px;
}
.card > div:first-child {
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
}
.card > div:last-child {
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
}
.card .border-dotted {
  min-height: 320px;
  display: flex;
  border: 2px dotted var(--wiz-color-skyblue);
  border-radius: 15px;
  background-color: var(--wiz-color-blue-lt);
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.card .border-dotted .text {
  font-size: 20px;
  font-family: "wiz-eb";
  color: var(--wiz-color-skyblue);
}
.card .border-dotted > * {
  border-radius: 15px;
}
.card .tag-inputs > * {
  display: inline-block;
  margin: 4px;
  line-height: 1;
}
.card .tag-inputs .form-control {
  width: 180px;
  border: none;
  text-align: left;
  padding: 0;
}
.card .tag-inputs .form-control:focus {
  box-shadow: none;
  outline: none;
}
.card .card-footer {
  display: flex;
  align-items: center;
}

.card-upload .file-list {
  min-height: 120px;
}
.card-upload .w-1 {
  width: 8px;
  white-space: nowrap;
  text-align: center;
}
.card-upload .filename {
  word-break: break-all;
}
.card-upload th {
  text-align: center;
}
.card-upload tr .d-flex .form-control {
  border-radius: 36px;
}
.card-upload tr .actions {
  font-size: 24px;
}
.card-upload tr .actions .dropdown {
  display: inline-block;
}
.card-upload tr .actions .dropdown ul {
  display: none;
}
.card-upload tr .actions .dropdown ul i {
  width: 20px;
  margin-right: 4px;
}
.card-upload tr .actions .dropdown,
.card-upload tr .actions .btn {
  min-width: 48px;
  margin: 0;
}
.card-upload tr:hover .dropdown {
  display: inline-block;
}
.card-upload tr:hover .dropdown ul.show {
  display: block;
}
.card-upload tr:hover .dropdown,
.card-upload tr:hover .btn {
  opacity: 1;
}
.card-upload th,
.card-upload td {
  padding: 8px 12px;
  cursor: initial;
}
.card-upload th .icon-box,
.card-upload td .icon-box {
  display: inline-block;
}
.card-upload th a,
.card-upload td a {
  color: #2F374E;
  cursor: pointer;
}
.card-upload th .avatar-container,
.card-upload td .avatar-container {
  display: flex;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
  background-color: var(--wiz-color-border);
  color: #666;
}
.card-upload th .avatar-container.required,
.card-upload td .avatar-container.required {
  background-color: #92CEFF;
  color: #51ACF6;
}
.card-upload th .avatar-container i,
.card-upload td .avatar-container i {
  width: 32px;
  text-align: center;
}

.workspace-list-item {
  overflow: hidden;
}
.workspace-list-item .left-item {
  width: 360px;
  flex: none;
  overflow: auto;
  border-right: 1px solid var(--wiz-color-border);
}
.workspace-list-item .left-item .card {
  border: none;
  border-radius: 0 !important;
}
.workspace-list-item .left-item .card > * {
  border-radius: 0 !important;
}
.workspace-list-item .right-item {
  flex: auto;
}
.workspace-list-item .right-item .card {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0 !important;
}
.workspace-list-item .right-item .card > * {
  flex: none;
  border-radius: 0 !important;
}
.workspace-list-item .right-item .card .image-area {
  flex: auto;
  padding: 0;
  overflow: auto;
}
.workspace-list-item .right-item .card .image-area img {
  width: 100%;
}

.form-selectgroup-item {
  margin-bottom: 4px;
}
.form-selectgroup-item .form-selectgroup-label {
  width: 72px;
  padding: 5px 12px;
  text-align: center;
}

table {
  line-height: 1;
}

.custom-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5000;
}
.custom-loading .loading-content {
  max-width: 640px;
  width: 80%;
  color: var(--wiz-color-text);
}`],
})
export class PageAnalysisRequestComponent implements OnInit {

    public info: any = { tag: [], created: moment().format("YYYY-MM-DD"), title: '제목없음', description: '', extra: {} };

    public INFO: string = "info";
    public UPLOAD: string = "upload";
    public TRANSFORM: string = "transform";

    constructor(@Inject( Service)         public service: Service,@Inject( ChangeDetectorRef    )         public ref: ChangeDetectorRef    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/auth/login');
    }

    public async update() {
        let { code, data } = await wiz.call("update", { data: JSON.stringify(this.info) });
        if (code == 200)
            this.info.id = data;
        await this.service.render();
    }

    public async delete() {
        if (this.info.id)
            await wiz.call("delete", { id: this.info.id });
        location.href = "/hub/analysis/report";
    }

    public step: any = ((scope, self: any = {}) => {
        self.current = 'info';

        self.is = (target) => {
            return self.current == target;
        }

        self.active = (target) => {
            return self.current == target;
        }

        self.change = async (target) => {
            self.current = target;
            await scope.service.render();
        }

        return self;
    })(this);

    public tag: any = ((scope, self: any = {}) => {
        self.data = '';

        self.add = async () => {
            if (!self.data) return;
            if (scope.info.tag.includes(self.data)) return;
            scope.info.tag.push(self.data);
            self.data = '';
            await this.service.render();
        }

        return self;
    })(this);

    public step1: any = ((scope, self: any = {}) => {
        self.activated = false;

        self.active = async () => {
            await scope.step.change('info');
        }

        self.next = async () => {
            await scope.update();
            await scope.step2.active();
        }

        self.check = () => {
            let check = () => {
                if (!scope.info.subtype) return 2;
                if (scope.info.subtype.length == 0) return 2;
                return 0;
            }

            self.activated = check();
            return self.activated;
        }

        return self;
    })(this);

    public step2: any = ((scope, self: any = {}) => {
        self.data = [];

        self.loading = { show: false, progress: 0, percent: 0, message: '' };

        self.activated = false;

        self.active = async () => {
            await scope.step.change('upload');
            await self.load();
        }

        self.prev = async () => {
            await scope.update();
            await scope.step1.active();
        }

        self.next = async () => {
            if (self.check() === 0) {
                scope.info.status = 'transform';
                await scope.update();
                await scope.step3.active();
            }
        }

        self.load = async () => {
            let { code, data } = await wiz.call("files", { id: scope.info.id });
            self.data = data;
            self.data.sort((a, b) => a.name.localeCompare(b.name));
            await self.check();
            await this.service.render();
        }

        self.check = () => {
            let check = () => {
                let checker = {};
                let filenames = [];
                for (let i = 0; i < self.data.length; i++) {
                    try {
                        let filename = self.data[i].name;
                        filename = filename.split(".");
                        let extension = filename[filename.length - 1];

                        filename = self.data[i].name;
                        filename = filename.substring(0, filename.length - extension.length - 1);
                        if (filenames.indexOf(filename) == -1)
                            filenames.push(filename);

                        extension = extension.toLowerCase();
                        checker[extension] = true;
                    } catch (e) {
                    }
                }

                if (filenames.length == 1) {
                    scope.info.title = filenames[0];
                } else {
                    scope.info.title = '제목없음';
                }

                if (filenames.length > 1) return 3;
                if (!checker.hdr) return 1;
                if (!checker.raw && !checker.bsq && !checker.dat) return 2;
                return 0;
            }

            self.activated = check();
            return self.activated;
        }

        self.timer = (value: any) => {
            return moment(new Date(value * 1000)).format("YYYY-MM-DD HH:mm:ss");
        }

        self.size = (value: any) => {
            if (!value) return "0B";
            let kb = value / 1024;
            if (kb < 1) return value + "B";
            let mb = kb / 1024;
            if (mb < 1) return Math.round(kb * 100) / 100 + "KB";
            let gb = mb / 1024;
            if (gb < 1) return Math.round(mb * 100) / 100 + "MB";
            let tb = gb / 1024;
            if (tb < 1) return Math.round(gb * 100) / 100 + "GB";
            return Math.round(tb * 100) / 100 + "TB";
        }

        self.delete = async (item) => {
            await wiz.call('delete_file', { id: scope.info.id, filename: item.name });
            await self.load();
        }

        self.click = async () => {
            let files = await this.service.file.select();
            await self.upload(files);
        }

        self.drop = async ($event) => {
            $event.stopPropagation();
            $event.preventDefault();
            let files = await this.service.file.drop($event);
            await self.upload(files);
        }

        self.dragover = async ($event) => {
            $event.stopPropagation();
            $event.preventDefault();
        }

        self.dragenter = async ($event) => {
            $event.stopPropagation();
            $event.preventDefault();
        }

        self.dragleave = async ($event) => {
            $event.stopPropagation();
            $event.preventDefault();
        }

        self.upload = async (files: any) => {
            self.loading = { show: true, progress: 0, message: '' };
            await scope.service.render();

            let fd = new FormData();
            let filepath = [];
            for (let i = 0; i < files.length; i++) {
                if (!files[i].filepath) files[i].filepath = files[i].name;
                fd.append('file[]', files[i]);
                filepath.push(files[i].filepath);
            }
            fd.append("filepath", JSON.stringify(filepath));
            fd.append("id", scope.info.id);

            let url = wiz.url('upload');
            await this.service.file.upload(url, fd, async (percent, total, current) => {
                self.loading.message = 'Uploading... ' + self.size(current) + ' / ' + self.size(total) + " (" + Math.round(percent) + "%)";
                self.loading.progress = Math.round(percent);
                await scope.service.render();
            });

            self.loading = { show: false, progress: 0, message: '' };
            await scope.service.render();
            await self.load();
        }

        return self;
    })(this);

    public step3: any = ((scope, self: any = {}) => {
        self.active = async () => {
            if (!scope.info.extra) scope.info.extra = {};
            if (!scope.info.extra.red) scope.info.extra.red = 0;
            if (!scope.info.extra.green) scope.info.extra.green = 60;
            if (!scope.info.extra.blue) scope.info.extra.blue = 120;
            if (!scope.info.extra.weight) scope.info.extra.weight = 1;
            if (!scope.info.extra.eps) scope.info.extra.eps = 35;
            await self.check();
            await scope.step.change('transform');
        }

        self.check = async () => {
            let check = () => {
                let check_range = (v, min, max) => {
                    if (v === null) return false;
                    return (min <= v && v <= max);
                }
                if (!check_range(scope.info.extra.red, 0, 127)) return 1;
                if (!check_range(scope.info.extra.green, 0, 127)) return 1;
                if (!check_range(scope.info.extra.blue, 0, 127)) return 1;
                if (!check_range(scope.info.extra.weight, 0, 10240)) return 1;
                return 0;
            }

            self.activated = check();
            await scope.service.render();
            return self.activated;
        }

        self.prev = async () => {
            await scope.update();
            await scope.step2.active();
        }

        self.send = async () => {
            await scope.service.loading.show();
            try {
                await scope.update();
                await wiz.call('transform', { id: scope.info.id });
                await wiz.call('detect', { id: scope.info.id });
            } catch (e) {
            }
            location.href = "/hub/analysis/report/item/" + scope.info.id;
        }

        return self;
    })(this);
}

export default PageAnalysisRequestComponent;