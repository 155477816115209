import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.aside');
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-layout-aside',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/layout.aside/view.scss */
.wiz-aside-page {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 768px) {
  .wiz-aside-page {
    flex-direction: column;
  }
}
.wiz-aside-page .wiz-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background-color: var(--wiz-color-light-1);
}
.wiz-aside-page .wiz-content .wiz-top-menu {
  display: flex;
  height: 32px;
  text-align: right;
  align-items: center;
  border-bottom: 1px solid var(--wiz-color-border);
  color: var(--wiz-color-text);
  background: #fff;
}
.wiz-aside-page .wiz-content .wiz-top-menu .dropdown .btn {
  height: 20px;
  font-size: 13px;
}
.wiz-aside-page .wiz-content .wiz-top-menu .dropdown .dropdown-menu-arrow:before {
  left: unset;
  right: 0.75rem !important;
}
.wiz-aside-page .wiz-content .wiz-view {
  flex: auto;
  overflow: auto;
  background: var(--wiz-color-background-lt);
  height: 0;
}`],
})
export class LayoutAsideComponent implements OnInit {
    public loading: boolean = false;

    constructor(@Inject( Service)         public service: Service,@Inject( ChangeDetectorRef)         public ref: ChangeDetectorRef,@Inject( Router    )         public router: Router    ) { }

    public async ngOnInit() {
        await this.service.init(this);
    }

}

export default LayoutAsideComponent;