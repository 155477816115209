import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.workflow.info');
import { OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Service } from "src/libs/season/service";
import showdown from 'showdown';

@Component({
    selector: 'wiz-component-workflow-info',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/component.workflow.info/view.scss */
.info-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card-info {
  position: relative;
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-top: none;
}

.info-box {
  max-width: 200px;
  border-radius: 24px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.8);
  padding: 24px;
}

.card-btn {
  cursor: pointer;
}

.description {
  flex: auto;
  overflow: auto;
}

.actions {
  background: var(--wiz-color-blue-light);
}
.actions > * {
  display: inline-block;
  line-height: 1;
}
.actions .btn {
  width: 152px;
}

.fab {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 32px;
  height: 32px;
  line-height: 1;
  color: #fff;
  border-radius: 16px;
  background: var(--wiz-color-red);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
.fab:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

a.card-btn {
  background: var(--wiz-color-blue-light);
  color: var(--wiz-color-blue);
}
a.card-btn:hover {
  background: var(--wiz-color-blue);
  color: #fff;
}`],
})
export class ComponentWorkflowInfoComponent implements OnInit {
    @Input() item: any = {};
    @Input() full: boolean = false;
    @Input() actions: boolean = true;
    @Input() download: boolean = true;
    @Output() close = new EventEmitter<any>();
    @Output() open = new EventEmitter<any>();
    @Output() delete = new EventEmitter<any>();
    @Output() clone = new EventEmitter<any>();

    public logoStyle: any = { 'border-radius': '24px' };
    public coverStyle: any = {};

    constructor(@Inject( Service) public service: Service) {
    }

    public async ngOnInit() {
        if (this.item.featured && this.item.featured.length > 0)
            this.coverStyle['background-image'] = this.item.featured;
        if (this.item.logo && this.item.logo.length > 0)
            this.logoStyle['background-image'] = this.item.logo;

        await this.service.render();
    }

    public showdown(text) {
        let converter = new showdown.Converter();
        return converter.makeHtml(text);
    }

    public closeInfo() {
        this.close.emit(this.item);
    }

    public openInfo() {
        this.open.emit(this.item);
    }

    public deleteInfo() {
        this.delete.emit(this.item);
    }

    public cloneInfo() {
        this.clone.emit(this.item);
    }

    public async run() {
        let workflow_id = this.item.id;
        await this.service.loading.show();
        await wiz.call('run', { workflow_id });
        await this.service.loading.hide();
    }

    public async stop() {
        let workflow_id = this.item.id;
        await this.service.loading.show();
        await wiz.call('stop', { workflow_id });
        await this.service.loading.hide();
    }

    public async downloadInfo() {
        let workflow_id = this.item.id;
        let { code, data } = await wiz.call("get", { id: workflow_id });
        if (code != 200) return;
        await this.service.file.download(data, data.title + ".dwp");
    }
}

export default ComponentWorkflowInfoComponent;