import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.explore');
import { OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-page-explore',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/page.explore/view.scss */
.selected {
  opacity: 1 !important;
}

.col-md-3 {
  margin-top: 0;
}

.create-tab {
  max-width: 640px;
  background: var(--wiz-color-light) !important;
}
.create-tab .card {
  border-radius: 12px !important;
}

.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.wiz-modal .modal {
  display: contents;
  width: 420px;
}`],
})
export class PageExploreComponent implements OnInit, OnDestroy {
    public list: any = [];

    public search: any = {
        page: 1,
        text: ''
    };

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public created: any = null;
    public selected: any = {};
    public selectedStyle: any = { opacity: 1 };

    constructor(@Inject( Service)         public service: Service,@Inject( ChangeDetectorRef    )         public ref: ChangeDetectorRef    ) { }

    public interval_id: number = 0;

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/auth/login');
        await this.load();
    }

    public async load(page: number = 1) {
        let { code, data } = await wiz.call("list", { page: page, text: this.search.text });
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;

        this.search.page = page;
        this.list = rows;

        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;

        await this.service.render();
    }

    public async pageMove(page: number) {
        await this.load(page);
    }

    public async close() {
        await this.select();
    }

    public async select(item: any = {}) {
        this.selected = item;
        if (this.selected.id) this.selectedStyle = { opacity: 0.3 };
        else this.selectedStyle = { opacity: 1 };
        await this.service.render();
    }

}

export default PageExploreComponent;