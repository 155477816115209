import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav.aside');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-component-nav-aside',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/component.nav.aside/view.scss */
aside.navbar-vertical.navbar-expand-lg {
  display: flex;
  position: relative;
  width: 240px;
  height: 100%;
  border-right: 1px solid var(--wiz-color-border);
  overflow: hidden;
  flex-direction: column;
}
@media (max-width: 768px) {
  aside.navbar-vertical.navbar-expand-lg {
    width: 100%;
    z-index: 99999;
    padding: 0;
    border-right: none;
    height: auto;
    flex-direction: row;
    height: 64px;
    flex: none;
  }
}

.navbar-brand-container {
  width: 100%;
  display: flex;
  text-align: center;
  height: 64px;
  background: var(--wiz-color-blue);
}
.navbar-brand-container .navbar-brand {
  flex: auto;
}
.navbar-brand-container .navbar-brand img {
  height: 32px;
}
.navbar-brand-container .navbar-toggler {
  color: #fff;
  align-items: flex-end;
  margin-left: 16px;
  margin-right: 16px;
}

.navbar-collapse {
  width: 100%;
  flex: auto;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
}
@media (max-width: 768px) {
  .navbar-collapse {
    height: calc(100% - 64px);
    position: fixed;
    top: 64px;
    left: 0;
  }
}
.navbar-collapse .navbar-nav {
  margin: 0 !important;
}
.navbar-collapse .navbar-nav .nav-link,
.navbar-collapse .navbar-nav .dropdown-item {
  color: var(--wiz-color-text);
}
.navbar-collapse .navbar-nav .nav-item {
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--wiz-color-border);
  cursor: pointer;
  align-items: center;
}
.navbar-collapse .navbar-nav .nav-item .d-flex {
  flex: auto;
  padding: 7px 16px;
  align-items: center;
}
.navbar-collapse .navbar-nav .nav-item.active {
  background-color: var(--wiz-color-blue-light);
}
.navbar-collapse .navbar-nav .nav-item .nav-icon {
  width: 20px;
  text-align: center;
  margin-right: 8px;
  font-size: 16px;
}
.navbar-collapse .navbar-nav .nav-item .nav-link {
  font-size: 16px;
  flex: auto;
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 0;
}
.navbar-collapse .navbar-nav .nav-item .nav-indicator {
  display: none;
}
.navbar-collapse .navbar-nav .nav-item .nav-indicator .status-indicator {
  width: 46px;
  height: 46px;
}
.navbar-collapse .navbar-nav .nav-item .nav-indicator .status-indicator .status-indicator-circle {
  --tblr-status-circle-size: 8px;
}
.navbar-collapse .navbar-nav .nav-item.active .nav-indicator {
  display: block;
}
.navbar-collapse .navbar-nav .nav-item:hover {
  background-color: var(--wiz-color-light);
}
.navbar-collapse .navbar-nav .nav-item:first-child {
  border-top: none;
}
.navbar-collapse .navbar-nav .nav-item:last-child {
  border-bottom: 1px solid var(--wiz-color-border);
}
.navbar-collapse .navbar-nav .nav-item-header {
  display: block;
  font-size: 14px;
  padding: 2px 12px;
  background: var(--wiz-color-light);
}
.navbar-collapse .navbar-nav .dropdown-menu.active {
  display: block;
}
.navbar-collapse .navbar-nav .dropdown-item {
  padding-left: 24px !important;
  font-size: 14px;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.navbar-collapse .navbar-nav span.nav-icon {
  margin-right: 8px;
}`],
})
export class ComponentNavAsideComponent implements OnInit {
    public isMenuCollapsed: boolean = true;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

}

export default ComponentNavAsideComponent;