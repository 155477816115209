import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.dizest.cron');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-component-dizest-cron',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/component.dizest.cron/view.scss */
.workspace-tab {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--wiz-color-light);
  position: relative;
}
.workspace-tab .workspace-tab-header {
  display: flex;
  background: var(--wiz-color-blue);
  padding: 4px 12px;
  height: 40px;
  align-items: center;
}
.workspace-tab .workspace-tab-header .workspace-tab-header-title {
  color: white;
  font-family: "wiz-eb";
  font-size: 16px;
}
.workspace-tab .workspace-tab-header .close-btn {
  font-size: 16px;
  cursor: pointer;
}
.workspace-tab .workspace-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  flex: 1;
}
.workspace-tab .workspace-tab-body {
  padding: 16px;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: auto;
}
.workspace-tab .workspace-tab-body .card {
  border-radius: 12px;
}

td, th {
  padding: 12px;
}`],
})
export class ComponentDizestCronComponent implements OnInit {
    @Input() workflow: any;

    public list: any = [];
    public query: any = { time: '', spec: '' };

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        this.query.spec = this.workflow.kernel();
        await this.refresh();
    }

    public async refresh() {
        let { data } = await this.workflow.request.post("cron_list", {}, true);
        this.list = data;
        await this.service.render();
    }

    public async remove(comment) {
        let { code, data } = await this.workflow.request.post("cron_remove", { comment: comment });
        await this.refresh();
    }

    public async add() {
        let { code, data } = await this.workflow.request.post("cron_add", this.query);
        this.query = {};
        await this.refresh();
    }

}

export default ComponentDizestCronComponent;