import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.system');
import { OnInit, ChangeDetectorRef } from "@angular/core";
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-page-admin-system',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/page.admin.system/view.scss */
.card {
  border-radius: 14px;
}
.card .img-responsive {
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
}
.card > div:last-child {
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
}

td {
  vertical-align: top;
}

.badge {
  padding: 4px 8px;
}`],
})
export class PageAdminSystemComponent implements OnInit {

    public initialized: boolean = false;
    public data: any = {};

    constructor(@Inject( Service)         public service: Service,@Inject( ChangeDetectorRef    )         public ref: ChangeDetectorRef    ) { }

    public async ngOnInit() {
        await this.service.loading.show();
        await this.service.init();
        await this.service.auth.allow('admin', '/');
        await this.load();
        this.initialized = true;
        await this.service.loading.hide();
    }

    public async load() {
        let { data } = await wiz.call("load");
        this.data = data;
        await this.service.render();
    }

    public async restart() {
        await this.service.loading.show();
        try {
            await wiz.call("restart");
        } catch (e) {
        }

        while (true) {
            try {
                await this.load();
                break;
            } catch (e) {
                await this.service.render(500);
            }
        }
        await this.service.loading.hide();
    }

    public async update() {
        await this.service.loading.show();
        await wiz.call("update", { data: JSON.stringify(this.data.config) });
        location.reload();
    }

    public async uploadLogo() {
        this.data.config.logo = await this.service.file.read({ type: 'image', accept: 'image/*', width: 180, quality: 1 });
        await this.service.render();
    }

    public async uploadIcon() {
        this.data.config.icon = await this.service.file.read({ type: 'image', accept: 'image/*', width: 48, quality: 1 });
        await this.service.render();
    }
}

export default PageAdminSystemComponent;