import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.workflow.editor');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-page-workflow-editor',
template: templateSource || '',
    styles: [``],
})
export class PageWorkflowEditorComponent implements OnInit {
    public ID: string;
    constructor(@Inject( Service    )         public service: Service    ) {
        this.ID = WizRoute.segment.id;
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/auth/login');
    }
}

export default PageWorkflowEditorComponent;