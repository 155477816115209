import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.dizest.apps');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/season/service";
import $ from "jquery";

@Component({
    selector: 'wiz-component-dizest-apps',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/component.dizest.apps/view.scss */
.app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.app .app-search {
  display: flex;
  padding: 14px 8px;
  border-bottom: 1px solid var(--wiz-color-border);
  height: 50px;
  background: var(--wiz-color-blue-light);
}
.app .app-search .btn,
.app .app-search .form-control {
  height: 28px;
}
.app .app-list {
  overflow-y: auto;
}
.app .app-list .no-items {
  font-size: 18px;
  font-weight: 1000;
  color: #555;
}
.app .app-list .no-items .btn.btn-primary {
  border-radius: 32px;
}
.app .app-list .no-items i {
  font-size: 48px;
  color: var(--wiz-color-blue);
}
.app .app-list .app-category {
  background: var(--wiz-color-light);
  color: var(--wiz-color-text);
  padding: 4px 12px;
  border-bottom: 1px solid var(--wiz-color-border);
  font-size: 14px;
  line-height: 1.2;
}
.app .app-list .app-info {
  border-bottom: 1px solid var(--wiz-color-border);
  line-height: 1;
  padding: 8px 16px;
  cursor: grab;
  display: flex;
  align-items: center;
}
.app .app-list .app-info :active {
  cursor: grabbing;
}
.app .app-list .app-info.active {
  background: var(--wiz-color-blue-light);
}
.app .app-list .app-info .title-area {
  flex: auto;
}
.app .app-list .app-info .title-area .app-header {
  font-size: 14px;
  font-weight: 1000;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  background: transparent;
}
.app .app-list .app-info .title-area .app-namespace {
  font-size: 12px;
}
.app .app-list .app-info:hover {
  background: var(--wiz-color-blue-light);
}
.app .app-list .app-info .btn i {
  font-size: 12px;
  width: 12px;
  height: 12px;
}`],
})
export class ComponentDizestAppsComponent implements OnInit {
    @Input() workflow: any;
    public alert: any;

    constructor(@Inject( Service) public service: Service) {
        this.alert = this.service.alert.localize({
            title: "Are you sure?",
            message: "Do you really want to remove app? What you've done cannot be undone."
        });
    }

    public keyword: string = '';

    public async ngOnInit() { }

    public match(item: any, keyword: string) {
        if (item.title.toLowerCase().indexOf(keyword.toLowerCase()) > -1)
            return true;
        return false;
    }

    public async search() {
        await this.service.render();
    }

    public async create() {
        this.workflow.app.create();
        await this.service.render();
    }

    public async import() {
        let dwa = await this.service.file.read({ type: 'json', accept: '.dwa' });
        await this.workflow.app.create(dwa);
        await this.service.render();
    }

    public drop: any = () => {
        let scope = this;
        return async (event) => {
            for (let i = 0; i < event.dataTransfer.files.length; i++) {
                let reader = new FileReader();
                reader.onload = async (readerEvent) => {
                    try {
                        let dwa = JSON.parse(readerEvent.target.result);
                        await scope.workflow.app.create(dwa);
                        await this.service.render();
                    } catch (e) {
                        toastr.error('Not supported file format');
                    }
                };
                reader.readAsText(event.dataTransfer.files[i]);
            }
        }
    }

    public async add(item: any) {
        this.workflow.flow.create(item.id, {}, -1);
        await this.service.render();
    }

    public async delete(item: any) {
        let res = await this.alert.show();
        if (!res) return;
        this.workflow.app.delete(item.id);
        await this.service.render();
    }

    public drag(event: any, item: any) {
        event.dataTransfer.setData("app-id", item.id);
    }

    public async hoverin(item: any) {
        $('#drawflow').addClass('app-hover');
        $('#drawflow .drawflow-node').removeClass('app-hover');
        $('#drawflow .drawflow-node.' + item.id).addClass('app-hover');
    }

    public async hoverout(item: any) {
        $('#drawflow').removeClass('app-hover');
        $('#drawflow .drawflow-node').removeClass('app-hover');
    }

}

export default ComponentDizestAppsComponent;