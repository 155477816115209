import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.dizest.workflow');
import { OnInit, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { Service } from "src/libs/season/service";
import $ from "jquery";

import toastr from 'toastr';
toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": true,
    "progressBar": false,
    "positionClass": "toast-bottom-center",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": 300,
    "hideDuration": 500,
    "timeOut": 1500,
    "extendedTimeOut": 1000,
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};


@Component({
    selector: 'wiz-component-dizest-workflow',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/component.dizest.workflow/view.scss */
.workspace {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.workspace-header {
  padding: 6px 24px;
  height: 54px;
  border: none;
  display: flex;
  align-items: center;
  background-color: var(--wiz-color-blue);
  color: #fff;
}
.workspace-header .page-header {
  width: 100%;
}
.workspace-header .btn-white {
  color: var(--wiz-color-blue);
}
.workspace-header .actions > * {
  margin-left: 8px;
  display: inline-block;
}
.workspace-header .actions .btn {
  min-width: 52px;
}
@media (max-width: 768px) {
  .workspace-header {
    padding: 6px 12px;
  }
}

.workspace-body {
  width: 100%;
  flex: auto;
  overflow: hidden;
  display: flex;
}
.workspace-body .zone-browser {
  width: 100%;
  position: relative;
  height: 100%;
  overflow: hidden;
  border-right: 1px solid var(--wiz-color-border);
  display: flex;
  flex-direction: column;
}
.workspace-body .zone-browser .browser-header {
  width: 100%;
  height: 48px;
  background: var(--wiz-color-blue);
  display: flex;
}
.workspace-body .zone-browser .browser-header .browser-margin {
  flex: 1;
}
.workspace-body .zone-browser .browser-header .browser-action {
  position: relative;
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.workspace-body .zone-browser .browser-header .browser-action .action-btn {
  font-size: 20px;
  color: #fff;
}
.workspace-body .zone-browser .browser-header .browser-action:hover .action-btn {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #fff;
  color: var(--wiz-color-blue);
  display: flex;
  justify-content: center;
  align-items: center;
}
.workspace-body .zone-browser .browser-header .browser-action.active .action-btn {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #fff;
  color: var(--wiz-color-blue);
  display: flex;
  justify-content: center;
  align-items: center;
}
.workspace-body .zone-browser .browser-header .browser-action.active .action-border {
  left: -40%;
  top: 100%;
  width: 180%;
  height: 8px;
  position: absolute;
  background-color: var(--wiz-color-blue);
  clip-path: url(#menu);
  transform: scale(1, -1);
  z-index: 1080;
}
.workspace-body .zone-browser .browser-content {
  width: 100%;
  flex: auto;
  overflow: hidden;
}
.workspace-body .zone-drawflow {
  height: 100%;
  flex: 1;
  overflow: hidden;
}
.workspace-body .zone-menuapp {
  flex: 1;
  height: 100%;
  overflow: hidden;
  border-left: 1px solid var(--wiz-color-border);
  display: none;
}
.workspace-body .zone-menuapp.show {
  display: block;
}
.workspace-body .zone-menu {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 42px;
  border-left: 1px solid var(--wiz-color-border);
}
.workspace-body .zone-menu .btn {
  display: block;
  border: none;
  border-radius: 0;
}
.workspace-body .zone-menu .btn,
.workspace-body .zone-menu .btn .hover-area {
  padding: 8px 12px;
}
.workspace-body .zone-menu .btn .hover-area {
  display: none;
  height: 32px;
  right: 0;
  margin-top: -28px;
  min-width: 80px;
  background-color: var(--wiz-color-blue);
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  padding-right: 0 !important;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.workspace-body .zone-menu .btn .hover-area .sidemenu-icon {
  width: 42px;
  text-align: center;
}
.workspace-body .zone-menu .btn:hover .hover-area {
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1000;
  right: 0;
}
.workspace-body .zone-menu .btn:hover .hover-area span {
  margin-left: auto;
  margin-right: 4px;
}
.workspace-body .show-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 49%;
  left: 0;
  width: 16px;
  height: 64px;
  background: var(--wiz-color-border);
  font-size: 16px;
  cursor: pointer;
  z-index: 1000;
}
.workspace-body .show-toggle:hover {
  background: var(--wiz-color-blue);
  color: #fff;
}
.workspace-body .hide-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 49%;
  right: 0;
  width: 16px;
  height: 64px;
  background: var(--wiz-color-border);
  font-size: 16px;
  cursor: pointer;
  z-index: 1900;
}
.workspace-body .hide-toggle:hover {
  background: var(--wiz-color-blue);
  color: #fff;
}

.codeflow {
  display: none;
}
.codeflow.show {
  display: contents;
}

.zone-editor {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.zone-editor .zone-app-variables {
  height: 300px;
}`],
})
export class ComponentDizestWorkflowComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() wpID: string;
    @Input() wpNamespace: string;
    @Input() wpReturnUrl: string;

    public initialized: boolean = false;
    public socket: any;
    public shortcuts: any = [];

    public data: any = {
        workflow: {},
        kernel: []
    };

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.loading.show();
        await this.request.info();
        await this.request.kernel();

        await this.workflow.bind();
        this.initialized = true;
        await this.service.render();

        this.workflow.menubar = this.menubar;
        this.workflow.browser = this.browser;
        this.workflow.request = this.request;

        await this.service.loading.hide();
    }

    public preventBack(e) {
        let confirmationMessage = "\o/";
        e.returnValue = confirmationMessage;
        return confirmationMessage;
    }

    public async ngAfterViewInit() {
        window.addEventListener("beforeunload", this.preventBack);

        this.shortcuts.push({
            key: ["cmd + s", "ctrl + s"],
            preventDefault: true,
            command: async () => {
                await this.workflow.update();
            }
        }, {
            key: ["shift + enter"],
            preventDefault: true,
            command: async () => {
                if (!this.workflow.flow.selected) return;
                this.workflow.run(this.workflow.flow.selected.id());
            }
        }, {
            key: ["cmd + r", "ctrl + r"],
            preventDefault: true,
            command: async () => {
                if (!this.workflow.flow.selected) return;
                this.workflow.run(this.workflow.flow.selected.id());
            }
        }, {
            key: ["esc"],
            preventDefault: true,
            command: async () => {
                await this.workflow.flow.unselect();
                $('#drawflow').click();
                $('#drawflow').focus();
            }
        }, {
            key: ["up"],
            preventDefault: true,
            command: async () => {
                await this.workflow.flow.prev();
            }
        }, {
            key: ['down'],
            preventDefault: true,
            command: async () => {
                await this.workflow.flow.next();
            }
        });
        for (let i = 0; i < this.shortcuts.length; i++)
            this.shortcuts[i].allowIn = ['TEXTAREA', 'INPUT', 'SELECT'];

        this.workflow.shortcuts = this.shortcuts;
        await this.service.render();
    }

    public ngOnDestroy() {
        window.removeEventListener("beforeunload", this.preventBack);
        this.workflow.unbind();
    }

    public async requester(path: string, opts: any = {}, origin: boolean = false) {
        if (origin) {
            return await wiz.call(path, { workflow_id: this.wpID, 'namespace': this.wpNamespace, ...opts });
        }
        let { code, data } = await wiz.call(path, { workflow_id: this.wpID, 'namespace': this.wpNamespace, ...opts });
        if (code == 200)
            return data;
        throw new Error(data);
    }

    public request: any = ((scope: any, obj: any = {}) => {
        obj.info = async () => {
            let data: any = await scope.requester("get");
            for (let key in data.flow) {
                data.flow[key].log = "";
                data.flow[key].status = "";
                data.flow[key].index = "";
            }
            scope.data.workflow = data;
            document.title = data.title;
        };

        obj.kernel = async () => {
            scope.data.kernel = await scope.requester("kernel");
        };

        obj.status = async (log: boolean = false) => {
            let wpstatus = await scope.requester("status", { log });
            let flows = scope.workflow.flow.list();
            for (let flow_id of flows) {
                let flow_status = wpstatus[flow_id];
                if (!flow_status) continue;
                let flow = scope.workflow.flow(flow_id);
                let { status, index, log } = flow_status;
                if (log) {
                    flow.log.clear();
                    flow.log(log);
                }
                flow.status(status);
                flow.index(index);
            }
            await scope.service.render();
        };

        obj.post = async (fnname: string, opt: any = {}) => {
            return await scope.requester(fnname, opt, true);
        }

        obj.uimode = {};
        obj.uimode.render = (fnname) => {
            return wiz.url('render/' + this.wpNamespace + "/" + this.wpID + "/" + fnname);
        }

        return obj;
    })(this);

    public DRIVE_API: any = ((obj: any = {}) => {
        obj.url = (fnname) => {
            let url = wiz.url("drive_api/" + this.wpNamespace + "/" + this.wpID + "/" + fnname);
            return url;
        }

        obj.call = async (fnname, data) => {
            let url = "drive_api/" + this.wpNamespace + "/" + this.wpID + "/" + fnname;
            return await wiz.call(url, data);
        }

        return obj;
    })();

    public display: any = ((scope: any, obj: any = {}) => {
        obj.status = () => {
            try {
                if (this.data.workflow.status == 'stop') return 'status-secondary';
                if (this.data.workflow.status == 'running') return 'status-primary';
            } catch (e) { }
            return 'status-yellow';
        }
        return obj;
    })(this);

    public browser: any = ((scope: any, obj: any = {}) => {
        obj.show = true;
        obj.target = 'app';
        obj.style = { 'max-width': '320px' };

        obj.toggle = async () => {
            obj.show = !obj.show;
            await scope.service.render();
        }

        obj.tab = async (tab) => {
            obj.target = tab;
            await scope.service.render();
        }

        obj.is = (target: string | null) => {
            return obj.target == target;
        }

        obj.isnot = (target: string | null) => {
            return obj.target != target;
        }

        return obj;
    })(this);

    public menubar: any = ((scope: any, obj: any = {}) => {
        obj.current = '';
        obj.style = { 'max-width': '720px' };

        obj.toggle = async (target: string = '') => {
            if (obj.current == target) obj.current = '';
            else obj.current = target;
            await scope.service.render();
            if (scope.workflow.codeflow) {
                await scope.workflow.codeflow.render();
            }
        }

        obj.is = (target: string | null) => {
            return obj.current == target;
        }

        obj.isnot = (target: string | null) => {
            return obj.current != target;
        }

        return obj;
    })(this);

    // Workflow Components
    // - flow component
    // - app component
    // - workflow component
    public flow: any = ((scope: any, id: string, obj: any = {}) => {
        const FLOW_ID = id;
        const NODE_ID = '#node-' + FLOW_ID;

        obj.id = () => FLOW_ID;
        obj.node = () => NODE_ID;

        obj.spec = () => scope.data.workflow.flow[FLOW_ID];

        obj.data = () => obj.spec().data;
        obj.title = () => obj.spec().title ? obj.spec().title : obj.app().title();
        obj.inactive = () => obj.spec().inactive;
        obj.inputs = () => obj.spec().inputs;
        obj.outputs = () => obj.spec().outputs;

        obj.set = async (data: any = {}) => {
            for (let key in data) {
                obj.spec()[key] = data[key];
            }
            await scope.service.render();
        }

        obj.log = (data: string) => {
            data = data.replace(/\n/gim, '<br>');
            let flowdata = obj.spec();
            if (flowdata.log) flowdata.log = flowdata.log + data;
            else flowdata.log = data;

            let d = flowdata.log.split("<br>");
            flowdata.log = d.splice(d.length - 51 > 0 ? d.length - 51 : 0).join("<br>");

            $(NODE_ID + " .debug-message").remove();
            if (flowdata.log) {
                $(NODE_ID).append('<pre class="debug-message">' + flowdata.log + '</pre>');
                let element = $(NODE_ID + " .debug-message")[0];
                if (!element) return;
                element.scrollTop = element.scrollHeight - element.clientHeight;
            }
        }

        obj.log.clear = () => {
            let flowdata = obj.spec();
            flowdata.log = '';
            $(NODE_ID + " .debug-message").remove();
        }

        obj.status = (status: string = '') => {
            let flowdata = obj.spec();
            if (status == '')
                return flowdata.status;
            flowdata.status = status;
            $(NODE_ID).attr('status', status);
        }

        obj.index = async (index: string = '') => {
            $(NODE_ID + ' .finish-indicator').html(index);
            obj.set({ index: index });
        }

        obj.app = () => {
            try {
                let data = obj.spec();
                return scope.workflow.app(data.app_id);
            } catch (e) {
            }
            return {};
        }

        obj.select = async () => {
            await scope.workflow.flow.select(FLOW_ID);
        }

        obj.toggle = async () => {
            obj.spec().inactive = !obj.spec().inactive;
            await scope.workflow.drawflow.render();
            await scope.service.render();
        }

        return obj;
    });

    public app: any = ((scope: any, id: string, obj: any = {}) => {
        const APP_ID = id;
        obj.id = () => APP_ID;

        obj.spec = () => {
            return scope.data.workflow.apps[APP_ID];
        }

        obj.delete = () => {
            scope.workflow.app.delete(APP_ID);
        }

        obj.title = () => obj.spec().title;
        obj.logo = () => obj.spec().logo ? `url(${obj.spec().logo})` : '#fff';
        obj.version = () => obj.spec().version;
        obj.inputs = () => obj.spec().inputs;
        obj.outputs = () => obj.spec().outputs;
        obj.mode = () => obj.spec().mode;

        return obj;
    });

    public workflow: any = ((scope: any, obj: any = {}) => {
        obj.drawflow = null;
        obj.codeflow = null;
        obj.menubar = null;
        obj.browser = null;
        obj.DRIVE_API = this.DRIVE_API;
        obj.returnUrl = () => location.href = this.wpReturnUrl;

        obj.spec = () => {
            return scope.data.workflow;
        }

        obj.kernel = () => scope.data.workflow.kernel;
        obj.kernels = () => scope.data.kernel;
        obj.kernelname = () => {
            let current = scope.data.workflow.kernel;
            for (let kernel of scope.data.kernel)
                if (kernel.name == current)
                    return kernel.title;
            return current;
        };

        // app controller
        obj.app = (app_id: string) => {
            if (scope.data.workflow.apps[app_id])
                return scope.app(scope, app_id);
            return null;
        }

        obj.app.categories = () => {
            let data = obj.spec();
            let categories = [];

            for (let app_id in data.apps) {
                let category = data.apps[app_id].category;
                if (!category) category = 'undefined';
                if (categories.indexOf(category) == -1)
                    categories.push(category);
            }

            return categories;
        }

        obj.app.list = (category: boolean = false) => {
            let data = obj.spec();
            let apps = [];
            if (category) {
                for (let app_id in data.apps) {
                    if (category == 'undefined')
                        if (!data.apps[app_id].category || data.apps[app_id].category == '')
                            apps.push(data.apps[app_id]);
                    if (data.apps[app_id].category == category)
                        apps.push(data.apps[app_id]);
                }

            } else {
                for (let app_id in data.apps) {
                    apps.push(data.apps[app_id]);
                }
            }

            apps.sort((a, b) => {
                return a.title.localeCompare(b.title);
            });

            return apps;
        }

        obj.app.create = (specstruct: any = {}) => {
            let spec = {
                id: '',
                title: 'new app',
                version: '1.0.0',
                description: '',
                cdn: { js: [], css: [] },
                inputs: [],
                outputs: [],
                code: '',
                api: '',
                pug: '',
                js: '',
                css: '',
                logo: ''
            };

            for (let key in specstruct) {
                spec[key] = specstruct[key];
            }

            let wpdata = obj.spec();
            let app_id = this.service.random();
            while (wpdata.apps[app_id])
                app_id = this.service.random();
            spec.id = app_id;

            wpdata.apps[app_id] = spec;
            return app_id;
        }

        obj.app.delete = (app_id: string) => {
            let wpdata = obj.spec();

            // delete related flow
            for (let flow_id of obj.flow.list()) {
                let flow = obj.flow(flow_id);
                if (flow.app().id() == app_id) {
                    obj.flow.delete(flow_id);
                }
            }
            delete wpdata.apps[app_id]
            obj.flow.unselect();
            $('#drawflow').removeClass('app-hover');
            $('#drawflow .drawflow-node').removeClass('app-hover');
        }

        // flow controller
        obj.flow = (flow_id: string) => {
            if (scope.data.workflow.flow[flow_id])
                return scope.flow(scope, flow_id);
            return null;
        }

        obj.flow.list = () => {
            let data = obj.spec();
            let flows = [];
            for (let flow_id in data.flow) {
                flows.push(flow_id);
            }
            return flows;
        }

        obj.flow.create = (app_id: string, opt: any = {}, position: number = -1) => {
            let wpdata = obj.spec();
            let flow_id = app_id + "-" + new Date().getTime();
            while (wpdata.flow[flow_id])
                flow_id = app_id + "-" + new Date().getTime();
            let spec = {
                app_id: app_id,
                'class': '',
                data: {},
                description: '',
                id: app_id + "-" + new Date().getTime(),
                inputs: {},
                name: '',
                outputs: {},
                pos_x: opt.x,
                pos_y: opt.y,
                log: '',
                status: '',
                typenode: false
            };
            wpdata.flow[flow_id] = spec;
            let newflow = obj.flow(flow_id);
            if (obj.drawflow)
                obj.drawflow.node.create(newflow, opt.drop ? true : false);
            if (obj.codeflow)
                obj.codeflow.add(newflow, position);
            scope.service.render();
            obj.flow.select(flow_id);
            return newflow
        }

        obj.flow.delete = async (flow_id: string) => {
            let wpdata = obj.spec();
            let flow = obj.flow(flow_id);
            if (!flow) return;
            if (obj.codeflow) obj.codeflow.delete(flow);
            if (obj.drawflow) obj.drawflow.node.delete(flow);
            delete wpdata.flow[flow_id];
            await scope.service.render();
        }

        obj.flow.selected = null;

        obj.flow.isSelected = () => obj.flow.selected ? true : false;

        obj.flow.select = async (flow_id: string) => {
            let flow = obj.flow(flow_id);
            if (!flow) return;

            $('#drawflow').removeClass('app-hover');
            $('#drawflow .drawflow-node').removeClass('app-hover');
            $('#drawflow').addClass('selected');
            $('.drawflow-node').removeClass('selected');
            $('.drawflow .connection path').removeClass('selected');
            $('#node-' + flow_id).addClass('selected');
            $('.node_in_node-' + flow_id + ' path').addClass('selected');
            $('.node_out_node-' + flow_id + ' path').addClass('selected');

            obj.flow.selected = flow;
            await scope.service.render();
        }

        obj.flow.unselect = async () => {
            $('#drawflow').removeClass('selected');
            $('.drawflow-node').removeClass('selected');
            $('.drawflow .connection path').removeClass('selected');
            obj.flow.selected = null;

            if (scope.menubar.is("appinfo") || scope.menubar.is("uimode")) {
                scope.menubar.toggle();
            }

            await scope.service.render();
        }

        obj.flow.next = async () => {
            if (!obj.flow.selected)
                return await obj.flow.select(obj.codeflow.first());
            let next_id = obj.codeflow.next(obj.flow.selected.id());
            if (next_id != null) {
                await obj.flow.select(next_id);
                await obj.position.drawflow(next_id);
                await obj.position.codeflow(next_id);
            }
        }

        obj.flow.prev = async () => {
            if (!obj.flow.selected)
                return await obj.flow.select(obj.codeflow.first());
            let prev_id = obj.codeflow.prev(obj.flow.selected.id());
            if (prev_id != null) {
                await obj.flow.select(prev_id);
                await obj.position.drawflow(prev_id);
                await obj.position.codeflow(prev_id);
            }
        }

        obj.position = {};
        obj.position.codeflow = async (flow_id: string) => {
            if (!obj.codeflow) return;
            if ($('#codeflow-' + flow_id).length == 0)
                return false;

            let y_start = $('.codeflow-body').scrollTop();
            let y_end = y_start + $('.codeflow-body').height();

            let y = $('#codeflow-' + flow_id).position().top + y_start;
            let y_h = y + $('#codeflow-' + flow_id).height() + y_start;

            let checkstart = y_start < y && y < y_end;
            let checkend = y_start < y_h && y_h < y_end;
            if (!checkstart || !checkend) {
                $('.codeflow-body').scrollTop(y - $('#codeflow-' + flow_id + ' .codeflow-desc').height() - $('#codeflow-' + flow_id + ' .codeflow-header').height());
            }
        }

        obj.position.drawflow = async (flow_id: string) => {
            if (!obj.drawflow) return;
            if ($('#node-' + flow_id).length == 0)
                return false;
            let x = $('#node-' + flow_id).position().left;
            let y = $('#node-' + flow_id).position().top;
            let zoom = obj.drawflow.drawflow.zoom;

            let w = $('#drawflow').width() * zoom;
            let h = $('#drawflow').height() * zoom;

            let tx = Math.round(-x + (w / 2.4));
            let ty = Math.round(-y + (h / 4));

            obj.drawflow.drawflow.move({ canvas_x: tx, canvas_y: ty });

            obj.drawflow.position.x = tx;
            obj.drawflow.position.y = ty;
            await scope.service.render();
        }

        // workflow status manager
        obj.is = (status) => {
            if (scope.data.workflow.status == status) {
                return true;
            }
            return false;
        };

        obj.isnot = (status) => {
            if (scope.data.workflow.status == status) {
                return false;
            }
            return true;
        }

        obj.validate = async (data: any) => {
            data = JSON.parse(JSON.stringify(data));
            if (!data.title || data.title.length == 0) {
                toastr.error("App title is not filled.");
                return false;
            }

            let checker = {};
            for (let i = 0; i < data.inputs.length; i++) {
                if (!data.inputs[i].name || data.inputs[i].name.length == 0) {
                    toastr.error("Input name must be filled");
                    return false;
                }

                if (data.inputs[i].name.includes(" ")) {
                    toastr.error("Input name only allow alphabet and digits.");
                    return false;
                }

                if (data.inputs[i].name.match(/[^a-z0-9_]/gi)) {
                    toastr.error("Input name only allow alphabet and digits.");
                    return false;
                }

                if (checker[data.inputs[i].name]) {
                    toastr.error("Input name must be unique.");
                    return false;
                }

                checker[data.inputs[i].name] = true;
            }

            checker = {};
            for (let i = 0; i < data.outputs.length; i++) {
                if (!data.outputs[i].name || data.outputs[i].name.length == 0) {
                    await $loading.hide();
                    toastr.error("Output name must be filled");
                    return false;
                }

                if (data.outputs[i].name.includes(" ")) {
                    await $loading.hide();
                    toastr.error("Output name only allow alphabet and digits.");
                    return false;
                }

                if (data.outputs[i].name.match(/[^a-z0-9_]/gi)) {
                    await $loading.hide();
                    toastr.error("Output name only allow alphabet and digits.");
                    return false;
                }

                if (checker[data.outputs[i].name]) {
                    await $loading.hide();
                    toastr.error("Output name must be unique.");
                    return false;
                }

                checker[data.outputs[i].name] = true;
            }

            return true;
        }

        obj.update = async (render: boolean = true) => {
            let codeflow = this.workflow.codeflow.data;
            let orderindex = {};
            for (let i = 0; i < codeflow.length; i++)
                orderindex[codeflow[i].id] = i + 1;
            let spec: any = obj.spec();
            let data: any = JSON.parse(JSON.stringify(spec));

            for (let app_id in data.apps) {
                if (await obj.validate(data.apps[app_id]) == false) {
                    return;
                }
            }

            for (let flow_id in data.flow) {
                delete data.flow[flow_id].log;
                delete data.flow[flow_id].status;
                delete data.flow[flow_id].index;
                if (orderindex[flow_id])
                    data.flow[flow_id].order = orderindex[flow_id];
                if (!data.flow[flow_id].order)
                    data.flow[flow_id].order = new Date().getTime();
            }

            data = JSON.stringify(data);
            await scope.requester("update", { data }, true);
            if (render) {
                await obj.drawflow.render();
                toastr.success("Saved");
            }

            if (obj.uimodeRender) {
                await obj.uimodeRender();
            }
        }

        obj.refresh = async (log: boolean = false) => {
            await this.request.status(log);
        }

        obj.reload = async () => {
            window.removeEventListener("beforeunload", this.preventBack);
            location.reload();
        }

        obj.stop = async () => {
            await scope.service.loading.show();
            let flows = obj.flow.list();
            for (let i = 0; i < flows.length; i++) {
                let flow = obj.flow(flows[i]);
                if (flow.status() == 'running' || flow.status() == 'pending')
                    flow.status('stop');
            }
            await scope.requester("stop");
        }

        obj.kill = async () => {
            await scope.service.loading.show();
            obj.SIGKILL = true;
            await scope.requester("kill");
            window.removeEventListener("beforeunload", this.preventBack);
            location.reload();
        }

        obj.start = async (spec) => {
            obj.SIGSTART = true;
            scope.data.workflow.status = 'onstart';
            await scope.service.loading.show();
            await scope.requester("start", { spec: spec });
        }

        obj.run = async (flow_id: string | null = null) => {
            if (flow_id) {
                let flow = obj.flow(flow_id);
                if (flow.status() == 'running' || flow.status() == 'pending')
                    return;
                flow.log.clear();
                flow.status('pending');
                await obj.update(false);
                await obj.flow.select(flow_id);
                await scope.requester("run", { flow: flow_id });
            } else {
                let flows = obj.flow.list();
                for (let i = 0; i < flows.length; i++) {
                    let flow = obj.flow(flows[i]);
                    if (flow.inactive()) continue;
                    flow.log.clear();
                    flow.status('pending');
                }
                await scope.requester("run");
            }
        }

        obj.bind = async () => {
            scope.socket = wiz.socket();
            scope.socket.on("connect", async () => {
                scope.socket.emit("join", { workflow_id: this.wpID, 'namespace': this.wpNamespace });
            });

            scope.socket.on("kernel.status", async (message: any) => {
                if (obj.SIGKILL) return;
                let { data } = message;

                if (obj.SIGSTART) {
                    if (data == 'ready') {
                        obj.SIGSTART = false;
                        scope.data.workflow.status = data;
                        await this.request.info();
                        await scope.service.loading.hide();
                    }
                } else {
                    scope.data.workflow.status = data;
                    await scope.service.render(1000);
                    await scope.service.loading.hide()
                }
            });

            scope.socket.on("flow.status", async (message: any) => {
                let { flow_id, data } = message;
                let flow = obj.flow(flow_id);
                if (!flow) return;
                flow.status(data);
            });

            scope.socket.on("flow.index", async (message: any) => {
                let { flow_id, data } = message;
                let flow = obj.flow(flow_id);
                if (!flow) return;
                flow.index(data);
            });

            scope.socket.on("flow.log.clear", async (message: any) => {
                let { flow_id } = message;
                let flow = obj.flow(flow_id);
                if (!flow) return;
                flow.log.clear();
            });

            scope.socket.on("flow.log", async (message: any) => {
                let { flow_id, data } = message;
                let flow = obj.flow(flow_id);
                if (!flow) return;
                flow.log(data);
            });
        }

        obj.unbind = async () => {
            scope.socket.disconnect();
        }

        return obj;
    })(this);

}

export default ComponentDizestWorkflowComponent;