import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.card.dataset');
import { OnInit, Input } from '@angular/core';

@Component({
    selector: 'wiz-component-card-dataset',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/component.card.dataset/view.scss */
.card {
  border-radius: 14px;
  cursor: pointer;
  width: 100%;
}
.card .img-responsive {
  width: 100%;
  display: block;
  padding: 0;
  background: no-repeat center/cover;
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
}
.card > div:last-child {
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
}
.card .featured-area {
  position: relative;
}
.card .featured-area .status-area {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  border-top-left-radius: 13px;
  border-bottom-right-radius: 13px;
  border-right: 1px solid var(--wiz-color-border);
  border-bottom: 1px solid var(--wiz-color-border);
  padding: 4px 12px;
  width: 120px;
  text-align: center;
  z-index: 10;
}
.card:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.card .card-title .text-reset {
  font-size: 16px;
  white-space: nowrap;
  word-break: break-all;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card .card-footer {
  display: flex;
  align-items: center;
}
.card .card-footer .text-area {
  overflow: hidden;
}
.card .card-footer.has-logo .text-reset {
  width: 168px;
}`],
})
export class ComponentCardDatasetComponent implements OnInit {
    @Input() title: string = "";
    @Input() updated: string = "";
    @Input() logo: string = "";
    @Input() cover: string = "";
    @Input() status: string = "";
    @Input() link: string = "";

    public statusClass: any = {
        running: 'bg-blue',
        error: 'bg-red',
        ready: 'bg-yellow'
    };
    public coverStyle: any = {};

    public ngOnInit() {
        this.coverStyle = {
            position: "relative",
            'background-image': "url('" + this.cover + "')"
        };
    }

    public move() {
        if (this.link)
            location.href = this.link;
    }

}

export default ComponentCardDatasetComponent;