import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.placeholder.content');
import { OnInit } from '@angular/core';

@Component({
    selector: 'wiz-component-placeholder-content',
template: templateSource || '',
    styles: [``],
})
export class ComponentPlaceholderContentComponent implements OnInit {
    public ngOnInit() {
    }
}

export default ComponentPlaceholderContentComponent;