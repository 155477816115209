import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.dizest.info.app');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/season/service";
import showdown from 'showdown';
import $ from 'jquery';
import toastr from 'toastr';

@Component({
    selector: 'wiz-component-dizest-info-app',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/component.dizest.info.app/view.scss */
.workspace-tab {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--wiz-color-light);
}
.workspace-tab .workspace-tab-header {
  display: flex;
  background: var(--wiz-color-blue);
  padding: 4px 12px;
  height: 40px;
  align-items: center;
}
.workspace-tab .workspace-tab-header .workspace-tab-header-title {
  color: white;
  font-family: "wiz-eb";
  font-size: 16px;
}
.workspace-tab .workspace-tab-header .close-btn {
  font-size: 16px;
  cursor: pointer;
}
.workspace-tab .workspace-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  flex: 1;
}
.workspace-tab .workspace-tab-body {
  padding: 16px;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: auto;
}
.workspace-tab .workspace-tab-body .card {
  border-radius: 12px;
}`],
})
export class ComponentDizestInfoAppComponent implements OnInit {
    @Input() workflow: any;

    public monaco: any = {
        value: '',
        language: 'markdown',
        theme: "vs",
        fontSize: 14,
        automaticLayout: true,
        scrollBeyondLastLine: false,
        wordWrap: true,
        roundedSelection: false,
        glyphMargin: false,
        scrollbar: {
            vertical: "hidden",
            handleMouseWheel: false,
        },
        minimap: {
            enabled: false
        }
    }

    public initialize: boolean = false;
    public descriptionEditable: boolean = false;
    public data: any;
    public style: any = {};

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        if (!this.workflow.flow.selected) return;
        this.data = this.workflow.flow.selected.app().spec();
        this.style.logo = { 'background-image': 'url(' + this.data.logo + ')' };
        this.initialize = true;
        await this.service.render();
    }

    public async toggleEditable() {
        this.descriptionEditable = !this.descriptionEditable;
        await this.service.render();
    }

    public showdown(text) {
        let converter = new showdown.Converter();
        return converter.makeHtml(text);
    }

    public async init(event: any) {
        let { editor } = event;
        let monaco_auto_height = async () => {
            const LINE_HEIGHT = 21;
            const el = editor._domElement;
            let ui_line_counter = $(el).find('.margin-view-overlays .line-numbers').length;
            let counter = editor.getModel().getLineCount();
            let real_line = $(el).find('.view-lines .view-line').length;
            let height = real_line * LINE_HEIGHT;
            if (height < 105) height = 105;

            el.style.height = height + 'px';
            editor.layout();
            let resetcounter = 0;
            while (ui_line_counter != counter) {
                if (resetcounter > 20) break;
                ui_line_counter = $(el).find('.margin-view-overlays .line-numbers').length;
                counter = editor.getModel().getLineCount();
                real_line = $(el).find('.view-lines .view-line').length;
                real_line = real_line + 5;
                height = real_line * LINE_HEIGHT;

                if (height < 105) height = 105;
                el.style.height = height + 'px';
                editor.layout();
                resetcounter++;
            }
        }

        await monaco_auto_height();
        editor.onDidChangeModelDecorations(monaco_auto_height);
    }

    public async download(data: any) {
        await this.service.file.download(data, data.title + ".dwa");
    }

    public upload: any = ((obj: any = {}) => {
        obj.logo = async () => {
            try {
                this.data.logo = await this.service.file.read({ type: 'image', accept: 'image/*', width: 40, quality: 1 });
                this.style.logo = { 'background-image': 'url(' + this.data.logo + ')' };
                await this.service.render();
            } catch (e) {
                toastr.error(e);
            }
        }
        return obj;
    })();

    public clear: any = ((obj: any = {}) => {
        obj.logo = async () => {
            this.data.logo = '';
            this.style.logo = {};
            await this.service.render();
        }
        return obj;
    })();

}

export default ComponentDizestInfoAppComponent;