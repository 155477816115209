import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.dizest.variables');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-component-dizest-variables',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/component.dizest.variables/view.scss */
.workspace-variables {
  overflow: hidden;
  background-color: #fff;
  width: 100%;
  border-top: 1px solid var(--wiz-color-border);
}
.workspace-variables .table th {
  padding: 4px;
}
.workspace-variables .table th .btn {
  width: 32px;
  height: 20px;
}
.workspace-variables .table td {
  padding: 0;
}
.workspace-variables .table td .form-selectgroup-item {
  display: inline-block !important;
  padding-top: 6px;
}
.workspace-variables .table td .form-selectgroup-item .form-selectgroup-label {
  padding: 3px 12px !important;
  font-size: 12px;
}
.workspace-variables .table td .form-select {
  border: none !important;
  box-shadow: none !important;
  text-align: left;
}
.workspace-variables .table td .form-control {
  border: none !important;
  background: transparent !important;
  text-align: left;
  min-height: 32px;
}
.workspace-variables .table td .form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}
.workspace-variables .variables {
  height: 120px;
  overflow: auto;
}`],
})
export class ComponentDizestVariablesComponent implements OnInit {
    @Input() workflow: any;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
    }

    public app() {
        try {
            return this.workflow.flow.selected.app().spec();
        } catch (e) {
        }
        return { inputs: [], outputs: [] };
    }

}

export default ComponentDizestVariablesComponent;