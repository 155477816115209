import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.database');
import { OnInit, ChangeDetectorRef } from "@angular/core";
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-page-admin-database',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/page.admin.database/view.scss */
.card {
  border-radius: 14px;
}
.card .img-responsive {
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
}
.card > div:last-child {
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
}

td {
  vertical-align: top;
}

.badge {
  padding: 4px 8px;
}`],
})
export class PageAdminDatabaseComponent implements OnInit {

    public initialized: boolean = false;
    public data: any = {};

    constructor(@Inject( Service)         public service: Service,@Inject( ChangeDetectorRef    )         public ref: ChangeDetectorRef    ) { }

    public async ngOnInit() {
        await this.service.loading.show();
        await this.service.init();
        await this.service.auth.allow('admin', '/');
        await this.load();
        this.initialized = true;
        await this.service.loading.hide();
    }

    public async load() {
        let { data } = await wiz.call("load");
        this.data = data;
        await this.service.render();
    }

    public async update() {
        await this.service.loading.show();
        await wiz.call("update", { data: JSON.stringify(this.data) });
        location.reload();
    }

}

export default PageAdminDatabaseComponent;