import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.installation');
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-page-installation',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/page.installation/view.scss */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.scrollbox {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: auto;
  z-index: 4;
}

.wrapper {
  background: #3843D0;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.wrapper.form-success .container h1 {
  transform: translateY(85px);
}

.container {
  max-width: 480px;
  margin: 0 auto;
  padding: 10vh 0;
}

.bg-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 0;
}

.bg-bubbles li {
  position: absolute;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.15);
  bottom: -160px;
  -webkit-animation: square 25s infinite;
  animation: square 25s infinite;
  transition-timing-function: linear;
  border-radius: 20px;
}

.bg-bubbles li:nth-child(1) {
  left: 10%;
}

.bg-bubbles li:nth-child(2) {
  left: 20%;
  width: 80px;
  height: 80px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 17s;
  animation-duration: 17s;
}

.bg-bubbles li:nth-child(3) {
  left: 25%;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.bg-bubbles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  -webkit-animation-duration: 22s;
  animation-duration: 22s;
  background-color: rgba(255, 255, 255, 0.25);
}

.bg-bubbles li:nth-child(5) {
  left: 70%;
}

.bg-bubbles li:nth-child(6) {
  left: 80%;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  background-color: rgba(255, 255, 255, 0.2);
}

.bg-bubbles li:nth-child(7) {
  left: 32%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
}

.bg-bubbles li:nth-child(8) {
  left: 55%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 15s;
  animation-delay: 15s;
  -webkit-animation-duration: 40s;
  animation-duration: 40s;
}

.bg-bubbles li:nth-child(9) {
  left: 25%;
  width: 10px;
  height: 10px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 40s;
  animation-duration: 40s;
  background-color: rgba(255, 255, 255, 0.3);
}

.bg-bubbles li:nth-child(10) {
  left: 90%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 11s;
  animation-delay: 11s;
}

@-webkit-keyframes square {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-70vh) rotate(600deg);
  }
}
@keyframes square {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-70vh) rotate(600deg);
  }
}
.card {
  border-radius: 14px;
}
.card .img-responsive {
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
}
.card > div:last-child {
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
}

.card-header {
  padding: 12px;
  display: block;
  text-align: center;
}
.card-header .card-title {
  font-family: "wiz-eb";
  font-weight: 1000;
}

label {
  display: flex;
  align-items: center;
}`],
})
export class PageInstallationComponent implements OnInit {

    public step: number = 1;
    public data: any = {};
    public user: any = {};

    constructor(@Inject( Service)         public service: Service,@Inject( ChangeDetectorRef    )         public ref: ChangeDetectorRef    ) { }

    public async ngOnInit() {
        await this.service.init();
        if (this.service.auth.installed)
            location.href = "/";
        await this.load();
        await this.service.render();
    }

    public async load() {
        let { code, data } = await wiz.call("load");
        if (code != 200)
            return false;

        this.data = data;
        await this.service.render();
        return true;
    }

    public async configdb() {
        await this.service.loading.show();
        await wiz.call("update", { data: JSON.stringify(this.data) });
        let status = await this.load();
        if (!status) {
            location.href = "/";
            return;
        }

        this.step = 2;
        await this.service.render();
        await this.service.loading.hide();
    }

    public async prev() {
        this.step = 1;
        await this.service.render();
    }

    public async start() {
        await this.service.loading.show();
        if (this.user.password != this.user.password_re) {
            await this.service.alert.show({ title: "Error", message: "Password missmatched", cancel: false, action: "Confirm" });
            return;
        }

        let { code, data } = await wiz.call("start", this.user);
        if (code === 200) {
            location.href = "/";
            return;
        }

        await this.service.loading.hide();
        await this.service.alert.show({ title: "Error", message: data, cancel: false, action: "Confirm" });
    }
}

export default PageInstallationComponent;