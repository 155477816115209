import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { OnInit, ChangeDetectorRef } from "@angular/core";
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/page.main/view.scss */
.carousel-item {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 500px;
  text-align: center;
}

.blindside {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  display: table;
}

.circle-icon {
  width: 60%;
  margin: 0 auto;
  color: #0a57a4;
}

.card-hover:hover {
  background: rgba(255, 255, 255, 0.8);
}

.slider-context {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  height: 500px;
  color: white;
}
.slider-context .title-area {
  display: inline-block;
}
.slider-context table {
  margin: 0 auto;
  max-width: 100%;
}
.slider-context table .col {
  width: 40px;
  font-size: 18px;
}
.slider-context table .title {
  font-family: "SEASONEB";
  padding-top: 24px;
  padding-left: 18px;
  padding-right: 18px;
  font-size: 40px;
}
.slider-context table .title div {
  line-height: 1.2;
}
.slider-context table tr {
  align-items: center;
}
.slider-context table td {
  vertical-align: top;
}
.slider-context .subtitle {
  margin-top: 24px;
  font-size: 14px;
}
.slider-context .description {
  font-size: 16px;
  margin-top: 36px;
}

@media (max-width: 768px) {
  .carousel-item {
    min-height: 100vh;
  }
  .blindside {
    min-height: 100vh;
  }
  .slider-context {
    margin-top: 25vh;
  }
}
@media (min-width: 769px) {
  #intro-image {
    height: 35vw;
    margin: 32px auto;
  }
  #intro-image .intro-image-area {
    width: 35vw;
    height: 35vw;
    background-image: url("work.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    margin: 0 auto;
  }
  #intro-image:hover .intro-image-area {
    width: 100vw;
    position: absolute;
    left: 0;
    border-radius: 0;
    transition: width 1s;
  }
}
@media (max-width: 768px) {
  #intro-image {
    width: 80vw;
    height: 80vw;
  }
}
.blue-area {
  background-color: #0a57a4;
  color: white;
  font-size: 16px;
}
.blue-area h2 {
  font-size: 26px;
}
.blue-area h3 {
  font-size: 24px;
}

.special-point {
  font-family: "SEASONEB";
  color: #2dacad;
  font-size: 15px;
  word-break: break-word;
}

@media (max-width: 768px) {
  #main-slider {
    min-height: 0;
  }
  #main-slider .slider-context {
    margin-top: 24px;
    padding: 24px;
  }
  #main-slider .slider-context table .title {
    font-size: 32px;
  }
}
.featured-area {
  transition-property: backgournd-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.featured-area h3,
.featured-area h2 {
  transition-property: color !important;
  transition-duration: 0.1s;
  transition-timing-function: ease-out;
}

.featured-area:hover {
  background: #2dacad;
  transition: background 0.3s ease-out;
}
.featured-area:hover h2,
.featured-area:hover h3 {
  color: rgb(255, 255, 255) !important;
  transition: color 0.1s ease-out !important;
}
.featured-area:hover .circle-icon {
  color: white !important;
  fill: white !important;
}
.featured-area:hover svg path {
  fill: white;
}`],
})
export class PageMainComponent implements OnInit {
    public data: any = {};

    constructor(@Inject( Service)         public service: Service,@Inject( ChangeDetectorRef    )         public ref: ChangeDetectorRef    ) { }

    public async ngOnInit() {
        await this.service.init();
    }
}

export default PageMainComponent;