import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.analysis.report.item');
import { OnInit, ChangeDetectorRef, ElementRef, ViewChild, Input } from "@angular/core";
import { Service } from "src/libs/season/service";

import moment from 'moment';
import toastr from 'toastr';
toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": true,
    "progressBar": false,
    "positionClass": "toast-bottom-center",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": 300,
    "hideDuration": 500,
    "timeOut": 1500,
    "extendedTimeOut": 1000,
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

@Component({
    selector: 'wiz-page-analysis-report-item',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/page.analysis.report.item/view.scss */
.container {
  max-width: 720px;
}

.step-area {
  display: flex;
  align-items: center;
  justify-content: center;
}

textarea.form-control {
  height: auto;
  text-align: left;
  border: none;
  padding: 0;
  border-radius: 0;
  margin: 4px;
}
textarea.form-control:focus {
  box-shadow: none;
  outline: none;
}

.card {
  border-radius: 14px;
}
.card > div:first-child {
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
}
.card > div:last-child {
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
}
.card .border-dotted {
  min-height: 320px;
  display: flex;
  border: 2px dotted var(--wiz-color-skyblue);
  border-radius: 15px;
  background-color: var(--wiz-color-blue-lt);
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.card .border-dotted .text {
  font-size: 20px;
  font-family: "wiz-eb";
  color: var(--wiz-color-skyblue);
}
.card .border-dotted > * {
  border-radius: 15px;
}
.card .tag-inputs > * {
  display: inline-block;
  margin: 4px;
  line-height: 1;
}
.card .tag-inputs .form-control {
  width: 180px;
  border: none;
  text-align: left;
  padding: 0;
}
.card .tag-inputs .form-control:focus {
  box-shadow: none;
  outline: none;
}
.card .card-footer {
  display: flex;
  align-items: center;
}

.card-upload .file-list {
  min-height: 120px;
}
.card-upload .w-1 {
  width: 8px;
  white-space: nowrap;
  text-align: center;
}
.card-upload .filename {
  word-break: break-all;
}
.card-upload th {
  text-align: center;
}
.card-upload tr .d-flex .form-control {
  border-radius: 36px;
}
.card-upload tr .actions {
  font-size: 24px;
}
.card-upload tr .actions .dropdown {
  display: inline-block;
}
.card-upload tr .actions .dropdown ul {
  display: none;
}
.card-upload tr .actions .dropdown ul i {
  width: 20px;
  margin-right: 4px;
}
.card-upload tr .actions .dropdown,
.card-upload tr .actions .btn {
  opacity: 0;
  min-width: 48px;
  margin: 0;
}
.card-upload tr:hover .dropdown {
  display: inline-block;
}
.card-upload tr:hover .dropdown ul.show {
  display: block;
}
.card-upload tr:hover .dropdown,
.card-upload tr:hover .btn {
  opacity: 1;
}
.card-upload th,
.card-upload td {
  padding: 8px 12px;
  cursor: initial;
}
.card-upload th .icon-box,
.card-upload td .icon-box {
  display: inline-block;
}
.card-upload th a,
.card-upload td a {
  color: #2F374E;
  cursor: pointer;
}
.card-upload th .avatar-container,
.card-upload td .avatar-container {
  display: flex;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
  background-color: var(--wiz-color-border);
  color: #666;
}
.card-upload th .avatar-container.required,
.card-upload td .avatar-container.required {
  background-color: #92CEFF;
  color: #51ACF6;
}
.card-upload th .avatar-container i,
.card-upload td .avatar-container i {
  width: 32px;
  text-align: center;
}

.workspace-list-item {
  overflow: hidden;
}
.workspace-list-item .left-item {
  width: 360px;
  flex: none;
  overflow: auto;
  border-left: 1px solid var(--wiz-color-border);
}
.workspace-list-item .left-item .card {
  border: none;
  border-radius: 0 !important;
}
.workspace-list-item .left-item .card > * {
  border-radius: 0 !important;
}
.workspace-list-item .right-item {
  flex: auto;
}
.workspace-list-item .right-item .card {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0 !important;
}
.workspace-list-item .right-item .card > * {
  flex: none;
  border-radius: 0 !important;
}
.workspace-list-item .right-item .card .image-area {
  flex: auto;
  padding: 0;
  overflow: auto;
}
.workspace-list-item .right-item .card .image-area img {
  width: 100%;
}

.form-selectgroup-item {
  margin-bottom: 4px;
}
.form-selectgroup-item .form-selectgroup-label {
  width: 72px;
  padding: 5px 12px;
  text-align: center;
}

table {
  line-height: 1;
}

.image-area {
  position: relative;
}
.image-area .overray {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}
.image-area .bbox {
  position: relative;
}
.image-area img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.image-area .select-box {
  display: none;
  position: absolute;
  border: 1px solid red;
  z-index: 999;
}

.describe {
  overflow: auto;
}`],
})
export class PageAnalysisReportItemComponent implements OnInit {
    public ID: string;
    public APIURL: string;
    public mode: string = 'view';
    public info: any = { extra: {} };
    public inputs: any = {
        tag: ''
    };

    public select: any = { active: false, xs: 0, xe: 0, ys: 0, ye: 0, style: {} };

    @ViewChild('targetimage')
    public targetimage: ElementRef;

    constructor(@Inject( Service)         public service: Service,@Inject( ChangeDetectorRef    )         public ref: ChangeDetectorRef    ) {
        this.ID = WizRoute.segment.id;
        this.APIURL = wiz.url("");
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/auth/login');
        await this.load();
        await this.file.load();
    }

    public async load() {
        let { data } = await wiz.call("info", { id: this.ID });
        data.created = moment(data.created).format("YYYY-MM-DD");
        if (!data.extra) data.extra = {};
        if (!data.tag) data.tag = [];
        if (!data.id) data.id = this.ID;
        this.info = data;
        await this.service.render();
    }

    public async update(notoast: boolean = false) {
        let pd = JSON.parse(JSON.stringify(this.info));
        let { code, data } = await wiz.call("update", { data: JSON.stringify(pd) });
        if (code == 200 && !notoast) {
            toastr.success("저장되었습니다");
        }
        await this.service.render();
    }

    public async delete() {
        await wiz.call("delete", { id: this.info.id });
        location.href = "/hub/analysis/report";
    }

    public file: any = ((scope, self: any = {}) => {
        self.data = [];

        self.load = async () => {
            let { data } = await wiz.call("files", { id: scope.ID });
            self.data = data;
            self.data.sort((a, b) => a.name.localeCompare(b.name));
            await this.service.render();
        }

        self.timer = (value: any) => {
            return moment(new Date(value * 1000)).format("YYYY-MM-DD HH:mm:ss");
        }

        self.size = (value: any) => {
            if (!value) return "0B";
            let kb = value / 1024;
            if (kb < 1) return value + "B";
            let mb = kb / 1024;
            if (mb < 1) return Math.round(kb * 100) / 100 + "KB";
            let gb = mb / 1024;
            if (gb < 1) return Math.round(mb * 100) / 100 + "MB";
            let tb = gb / 1024;
            if (tb < 1) return Math.round(gb * 100) / 100 + "GB";
            return Math.round(tb * 100) / 100 + "TB";
        }

        return self;
    })(this);

    public tag: any = ((scope, self: any = {}) => {
        self.data = '';

        self.add = async () => {
            if (!self.data) return;
            if (scope.info.tag.includes(self.data)) return;
            scope.info.tag.push(self.data);
            self.data = '';
            await this.service.render();
        }

        return self;
    })(this);

    public zone() {
        let { active, xs, xe, ys, ye } = this.select;
        if (!active) return false;
        let area = Math.abs(xs - xe) * Math.abs(ys - ye);

        // if (area < 0.005) {
        //     this.select.style.display = "none";
        //     this.service.render();
        //     return false;
        // }

        if (area > 50) {
            return false;
        }

        let st: any = {};
        st.left = xs;
        if (xs > xe) st.left = xe;
        st.top = ys;
        if (ys > ye) st.top = ye;

        st.display = "block";
        st.left = st.left + "%";
        st.top = st.top + "%";
        st.width = Math.abs(xs - xe) + "%";
        st.height = Math.abs(ys - ye) + "%";

        let pos: any = {};
        pos.xs = xs < xe ? xs : xe;
        pos.xe = xs < xe ? xe : xs;
        pos.ys = ys < ye ? ys : ye;
        pos.ye = ys < ye ? ye : ys;

        this.select.pos = pos;
        this.select.style = st;
        this.service.render();

        return area;
    }

    public report_data: any = { rendered: false };

    public async closeReport() {
        this.mode = "view";
        await this.service.render();
    }

    public async report() {
        this.mode = 'report';
        this.report_data.rendered = false;
        await this.service.render();
        await this.service.render(500);

        let pd = JSON.parse(JSON.stringify(this.select.pos));
        pd.id = this.ID;
        let { code, data } = await wiz.call("report", pd);
        this.report_data = {};
        for (let key in data) {
            this.report_data[key] = data[key];
        }

        this.report_data.rendered = true;
        await this.service.render();
    }

    public dragdown(ev: any) {
        let { offsetX, offsetY } = ev;
        let { offsetWidth, offsetHeight } = this.targetimage.nativeElement;
        let x = offsetX / offsetWidth * 100;
        let y = offsetY / offsetHeight * 100;

        this.select.xs = x;
        this.select.ys = y;
        this.select.xe = x;
        this.select.ye = y;
        this.select.active = true;
        this.zone();
    }

    public dragup(ev: any) {
        let { offsetX, offsetY } = ev;
        let { offsetWidth, offsetHeight } = this.targetimage.nativeElement;
        let x = offsetX / offsetWidth * 100;
        let y = offsetY / offsetHeight * 100;
        this.select.xe = x;
        this.select.ye = y;
        this.select.active = false;
        this.zone();
        this.report();
    }

    public dragmove(ev: any) {
        if (!this.select.active) return;
        let { offsetX, offsetY } = ev;
        let { offsetWidth, offsetHeight } = this.targetimage.nativeElement;
        let x = offsetX / offsetWidth * 100;
        let y = offsetY / offsetHeight * 100;
        this.select.xe = x;
        this.select.ye = y;
        this.zone();
    }

    public transform: any = ((scope, self: any = {}) => {
        self.check = () => {
            let check = () => {
                let check_range = (v, min, max) => {
                    if (v === null) return false;
                    return (min <= v && v <= max);
                }
                if (!check_range(scope.info.extra.red, 0, 127)) return 1;
                if (!check_range(scope.info.extra.green, 0, 127)) return 1;
                if (!check_range(scope.info.extra.blue, 0, 127)) return 1;
                if (!check_range(scope.info.extra.weight, 0, 10240)) return 1;
                return 0;
            }

            self.activated = check();
            return self.activated;
        }

        self.send = async () => {
            await scope.service.loading.show();
            try {
                await scope.update(true);
                await wiz.call('transform', { id: scope.info.id });
                await wiz.call('detect', { id: scope.info.id });
            } catch (e) {
            }
            location.reload();
        }

        return self;
    })(this);

}

export default PageAnalysisReportItemComponent;