import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.release');
import { OnInit, ChangeDetectorRef } from "@angular/core";
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-page-admin-release',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/page.admin.release/view.scss */
.card {
  border-radius: 14px;
}
.card .img-responsive {
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
}
.card > div:last-child {
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
}

td {
  vertical-align: top;
}

.badge {
  padding: 4px 8px;
}`],
})
export class PageAdminReleaseComponent implements OnInit {

    public releases: any = [];

    constructor(@Inject( Service)         public service: Service,@Inject( ChangeDetectorRef    )         public ref: ChangeDetectorRef    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/');

        this.releases.push({
            name: "v2022.10.18.1547",
            log: [
                "[workflow] full change ui / ux",
                "[workflow] angular.js to angular upgrade",
                "[core] apply WIZ 2.0"
            ]
        });

        this.releases.push({
            name: "v2022.08.25.2154",
            log: [
                "[workflow] kernelspec error fixed in package install"
            ]
        });

        this.releases.push({
            name: "v2022.08.23.2317",
            log: [
                "[workflow] timer bug fixed (display by each workflow)"
            ]
        });

        this.releases.push({
            name: "v2022.08.23.2129",
            log: [
                "[workflow] fold codeflow when block > 5",
                "[workflow] app title editable",
                "[workflow] input/output sortable bug fixed"
            ]
        });

        this.releases.push({
            name: "v2022.08.23.2109",
            log: [
                "[workflow] customizable codeflow title",
                "[workflow] drag & drop sorting in codeflow",
                "[workflow] mobile optimized",
                "[workflow] enhanced flow status update",
                "[workflow] auto select kernel when single kernel"
            ]
        });

        this.releases.push({
            name: "v2022.08.23.1933",
            log: [
                "[workflow] add textarea option at flow input"
            ]
        });

        this.releases.push({
            name: "v2022.08.23.1808",
            log: [
                "[workflow] input/output validate bug fixed",
                "[workflow] app asc sorted by title",
                "[workflow] flow status trigger (for fix error)",
                "[workflow] support UI Mode on mobile"
            ]
        });

        this.releases.push({
            name: "v2022.08.23.1536",
            log: [
                "[admin] Releases Info",
                "[admin] UI / Core version update on web setting"
            ]
        });

        await this.service.render();
    }
}

export default PageAdminReleaseComponent;