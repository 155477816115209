import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.navbar');
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-layout-navbar',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/layout.navbar/view.scss */
.wiz-nav-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.wiz-nav-page > .wiz-view {
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow: auto;
  background-color: var(--wiz-color-light-1);
}
.wiz-nav-page > .wiz-view > * {
  flex: auto;
}
.wiz-nav-page > .wiz-view .wiz-footer {
  flex: none;
  color: #888;
  background: #3a3a3a;
  font-size: 16px;
  padding-top: 32px;
  padding-bottom: 32px;
}
.wiz-nav-page > .wiz-view .wiz-footer small {
  font-size: 14px;
}
.wiz-nav-page > .wiz-view .wiz-footer a {
  color: #888;
}
.wiz-nav-page > .wiz-view .wiz-footer a.color {
  color: var(--wiz-color-red);
  cursor: pointer;
}`],
})
export class LayoutNavbarComponent implements OnInit {
    public loading: boolean = false;

    constructor(@Inject( Service)         public service: Service,@Inject( ChangeDetectorRef)         public ref: ChangeDetectorRef,@Inject( Router    )         public router: Router    ) { }

    public async ngOnInit() {
        await this.service.init(this);
    }

}

export default LayoutNavbarComponent;