import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.analysis.report');
import { OnInit, ChangeDetectorRef } from "@angular/core";
import { Service } from "src/libs/season/service";

import toastr from 'toastr';
toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": true,
    "progressBar": false,
    "positionClass": "toast-bottom-center",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": 300,
    "hideDuration": 500,
    "timeOut": 1500,
    "extendedTimeOut": 1000,
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

@Component({
    selector: 'wiz-page-analysis-report',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/page.analysis.report/view.scss */
.selected {
  opacity: 1 !important;
}

.col-md-3 {
  margin-top: 0;
}

.create-tab {
  max-width: 640px;
  background: var(--wiz-color-light) !important;
}
.create-tab .card {
  border-radius: 12px !important;
}

.step-area {
  display: flex;
  align-items: center;
  justify-content: center;
}
.step-area > * {
  margin: 0 6px;
}

.card {
  border-radius: 14px;
  cursor: pointer;
}
.card:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}
.card .img-responsive {
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
}
.card > div:last-child {
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
}
.card .status-area {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  border-top-left-radius: 13px;
  border-bottom-right-radius: 13px;
  border-right: 1px solid var(--wiz-color-border);
  border-bottom: 1px solid var(--wiz-color-border);
  padding: 4px 12px;
  width: 120px;
  text-align: center;
  z-index: 10;
}
.card .text-area {
  width: 100%;
}
.card .text-area .card-title.nowrap {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}`],
})
export class PageAnalysisReportComponent implements OnInit {
    public list: any = [];

    public search: any = {
        page: 1,
        tag: '',
        subtype: ''
    };

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    constructor(@Inject( Service)         public service: Service,@Inject( ChangeDetectorRef    )         public ref: ChangeDetectorRef    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/auth/login');
        await this.load();
    }

    public async load(page: number = 1) {
        let { code, data } = await wiz.call("list", { page: page, tag: this.search.tag, subtype: this.search.subtype });
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;

        this.search.page = page;
        this.list = rows;

        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;

        await this.service.render();
    }

    public async pageMove(page: number) {
        await this.load(page);
    }

    public async select(item: any = {}) {
        location.href = "/hub/analysis/report/item/" + item.id;
    }

}

export default PageAnalysisReportComponent;