import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.dizest.codeflow');
import { OnInit, OnDestroy, Input } from '@angular/core';
import { Service } from "src/libs/season/service";
import showdown from 'showdown';
import $ from 'jquery';

@Component({
    selector: 'wiz-component-dizest-codeflow',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/component.dizest.codeflow/view.scss */
.workspace-tab {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--wiz-color-light);
}
.workspace-tab .workspace-tab-header {
  display: flex;
  background: var(--wiz-color-blue);
  padding: 4px 12px;
  height: 40px;
  align-items: center;
}
.workspace-tab .workspace-tab-header .workspace-tab-header-title {
  color: white;
  font-family: "wiz-eb";
  font-size: 16px;
}
.workspace-tab .workspace-tab-header .close-btn {
  font-size: 16px;
  cursor: pointer;
}
.workspace-tab .workspace-tab-body {
  padding: 0;
  overflow: hidden;
  height: 100%;
  flex: auto;
  position: relative;
}

.codeflow .status-indicator-circle {
  --tblr-status-circle-size: 8px !important;
}
.codeflow .codeflow-body {
  padding: 16px;
  overflow: auto;
  position: absolute;
  width: 100%;
  height: 100%;
}
.codeflow .codeflow-body .margin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}
.codeflow .codeflow-body .margin .btn {
  display: none;
}
.codeflow .codeflow-body .margin:hover .btn {
  display: block;
}
.codeflow .codeflow-body .card {
  margin: 0;
}
.codeflow .codeflow-body .card .w-96 {
  width: 96px;
}
.codeflow .codeflow-body .card .table th {
  padding: 4px;
}
.codeflow .codeflow-body .card .table th .btn {
  width: 32px;
  height: 20px;
}
.codeflow .codeflow-body .card .table td {
  padding: 0;
}
.codeflow .codeflow-body .card .table td .form-selectgroup-item {
  display: inline-block !important;
  padding-top: 6px;
}
.codeflow .codeflow-body .card .table td .form-selectgroup-item .form-selectgroup-label {
  padding: 3px 12px !important;
  font-size: 12px;
}
.codeflow .codeflow-body .card .table td .form-select {
  border: none !important;
  box-shadow: none !important;
  text-align: left;
}
.codeflow .codeflow-body .card .table td .form-control {
  border: none !important;
  background: transparent !important;
  text-align: left;
  min-height: 32px;
}
.codeflow .codeflow-body .card .table td .form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}
.codeflow .codeflow-body .card .codeflow-header {
  display: flex;
  align-items: center;
  padding: 0;
  background: var(--wiz-color-blue-light);
  height: 22px;
  border-bottom: 1px solid var(--wiz-color-border);
}
.codeflow .codeflow-body .card .codeflow-header input {
  background: transparent;
  border: none;
  box-shadow: none;
  flex: auto;
  margin-right: 4px;
}
.codeflow .codeflow-body .card .codeflow-header .badge {
  height: 21px;
  border-radius: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.codeflow .codeflow-body .card .codeflow-header .btn {
  font-size: 14px;
  border-radius: 0;
}
.codeflow .codeflow-body .card .codeflow-header .cursor-grab {
  cursor: grab !important;
}
.codeflow .codeflow-body .card .btn-sm {
  width: 32px;
}
.codeflow .codeflow-body .card .codeflow-options {
  display: none;
}
.codeflow .codeflow-body .card .codeflow-item {
  border-top: 1px solid var(--wiz-color-border);
}
.codeflow .codeflow-body .card .codeflow-item .table.card-table.table-sm {
  display: none;
}
.codeflow .codeflow-body .card .codeflow-item .codeflow-codearea {
  display: flex;
}
.codeflow .codeflow-body .card .codeflow-item .codeflow-codearea .item-body {
  width: 100%;
  flex: auto;
  min-height: 105px;
}
.codeflow .codeflow-body .card .codeflow-descarea {
  min-height: 0;
  background: var(--wiz-color-light-1);
}
.codeflow .codeflow-body .card .monaco-editor {
  max-height: 100%;
}
.codeflow .codeflow-body .codeflow-actions {
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 1001;
}
.codeflow .codeflow-body .codeflow-actions .btn {
  border-radius: 100%;
  width: 48px;
  height: 48px;
  font-size: 20px;
}
.codeflow .codeflow-body .codeflow-focused .codeflow-codearea {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.codeflow .codeflow-body .codeflow-selected .codeflow-header {
  background: var(--wiz-color-blue);
}
.codeflow .codeflow-body .codeflow-selected .codeflow-header input {
  color: #fff;
}
.codeflow .codeflow-body .codeflow-selected .codeflow-header ::placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}
.codeflow .codeflow-body .codeflow-selected .codeflow-options {
  display: block !important;
}
.codeflow .codeflow-body .codeflow-selected .codeflow-item .table.card-table.table-sm {
  display: table;
}
.codeflow .codeflow-body .codeflow-selected .codeflow-item .codeflow-codearea {
  border: 3px solid var(--wiz-color-skyblue);
}

.margin-bottom {
  height: 50vh;
}

.isdragging .codeflow-desc {
  max-height: 80px;
  overflow: hidden;
}
.isdragging .codeflow-item {
  display: none;
}
.isdragging .codeflow-options {
  display: none;
}
.isdragging .margin .btn {
  display: none !important;
}

.btn.btn-blue.btn-block.add-btn {
  margin-top: 24px;
}

.item-selected .codeflow-body .card {
  opacity: 0.6;
}
.item-selected .codeflow-body .codeflow-selected {
  opacity: 1;
}

.codeflow-logs {
  padding: 12px;
  max-height: 460px;
  overflow: auto;
}
.codeflow-logs .log-container {
  font-size: 14px;
}
.codeflow-logs .log-container img {
  margin: 0 auto;
}`],
})
export class ComponentDizestCodeflowComponent implements OnInit, OnDestroy {
    @Input() workflow: any;

    public monacopy: any = {
        value: '',
        language: 'python',
        theme: "vs",
        fontSize: 14,
        automaticLayout: true,
        scrollBeyondLastLine: false,
        wordWrap: true,
        roundedSelection: false,
        glyphMargin: false,
        scrollbar: {
            vertical: "hidden",
            handleMouseWheel: false,
        },
        minimap: {
            enabled: false
        }
    }

    public monacomd: any = {
        value: '',
        language: 'markdown',
        theme: "vs",
        fontSize: 14,
        automaticLayout: true,
        scrollBeyondLastLine: false,
        wordWrap: true,
        roundedSelection: false,
        glyphMargin: false,
        scrollbar: {
            vertical: "hidden",
            handleMouseWheel: false,
        },
        minimap: {
            enabled: false
        }
    }

    public status: any = {
        drag: false
    }

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        this.workflow.codeflow = this.codeflow;
        await this.codeflow.build();
    }

    public async ngOnDestroy() {
        this.workflow.codeflow = null;
    }

    public showdown(text: string) {
        let converter = new showdown.Converter();
        return converter.makeHtml(text);
    }

    public async init(event: any) {
        let { editor } = event;
        let monaco_auto_height = async () => {
            const LINE_HEIGHT = 21;
            const el = editor._domElement;
            let ui_line_counter = $(el).find('.margin-view-overlays .line-numbers').length;
            let counter = editor.getModel().getLineCount();
            let real_line = $(el).find('.view-lines .view-line').length;
            let height = real_line * LINE_HEIGHT;
            if (height < 105) height = 105;

            el.style.height = height + 'px';
            editor.layout();
            let resetcounter = 0;
            while (ui_line_counter != counter) {
                if (resetcounter > 20) break;
                ui_line_counter = $(el).find('.margin-view-overlays .line-numbers').length;
                counter = editor.getModel().getLineCount();
                real_line = $(el).find('.view-lines .view-line').length;
                real_line = real_line + 5;
                height = real_line * LINE_HEIGHT;

                if (height < 105) height = 105;
                el.style.height = height + 'px';
                editor.layout();
                resetcounter++;
            }
        }

        await monaco_auto_height();
        editor.onDidChangeModelDecorations(monaco_auto_height);
    }

    public isDescriptionHidden: boolean = false;

    public async toggleDescription() {
        this.isDescriptionHidden = !this.isDescriptionHidden
        await this.service.render();
    }

    public async create(index: number = -1) {
        let app_id = this.workflow.app.create();
        let flow = this.workflow.flow.create(app_id, {}, index);
        await flow.select();
    }

    public async drag(status: boolean, item: any) {
        this.status.drag = status;
        await this.service.render();

        if (status == false) {
            await item.select();
            await this.workflow.position.codeflow(item.id);
        }
    }

    public app() {
        return this.workflow.flow.selected.app().spec();
    }

    public initialized: boolean = true;

    public codeflow: any = ((scope: any, obj: any = {}) => {

        obj.data = [];

        obj.render = async () => {
            scope.initialized = false;
            await scope.service.render();
            scope.initialized = true;
            await scope.service.render();
        }

        obj.item = (id: string, order: number) => {
            let item: any = {
                id: id,
                order: order,
                editable: false
            };

            item.desc = {};
            item.desc.toggle = async () => {
                item.editable = !item.editable;
                await scope.service.render();
            }
            item.desc.isEditable = () => {
                return item.selected() && item.editable;
            }

            item.selected = () => {
                if (!scope.workflow.flow.selected) return false;
                if (scope.workflow.flow.selected.id() == item.id) return true;
                return false;
            }

            item.select = async () => {
                await scope.workflow.flow.select(item.id);
                await scope.workflow.position.drawflow(item.id);
            }

            item.delete = async () => {
                await scope.workflow.flow.delete(item.id);
            }

            item.flow = () => {
                try {
                    return scope.workflow.flow(item.id).spec();
                } catch (e) {
                    return {};
                }
            }

            item.app = () => {
                try {
                    return scope.workflow.flow(item.id).app().spec();
                } catch (e) {
                    return {};
                }
            }

            item.info = async () => {
                await this.workflow.flow.select(item.id);
                if (this.workflow.menubar.isnot('appinfo')) {
                    await this.workflow.menubar.toggle("appinfo");
                    await this.service.render();
                }
            }

            item.toggle = async () => {
                await scope.workflow.flow(item.id).toggle();
                await item.select();
            }

            item.up = async () => {
                obj.data.up(item);
                await scope.service.render();
            }

            item.down = async () => {
                obj.data.down(item);
                await scope.service.render();
            }

            return item;
        }

        obj.build = async () => {
            let res: any = [];
            let spec = scope.workflow.spec();
            for (let flow_id in spec.flow) {
                let order = spec.flow[flow_id].order;
                if (!order) order = new Date().getTime();
                let item = obj.item(flow_id, order);
                res.push(item);
            }
            res.sort((a, b) => a.order - b.order);
            obj.data = res;
            await scope.service.render();
        }

        obj.delete = (flow: any) => {
            let flow_id = flow.id();
            let removeItemIndex: number = -1;
            for (let i = 0; i < obj.data.length; i++) {
                if (obj.data[i].id == flow_id) {
                    removeItemIndex = i;
                    break;
                }
            }
            if (removeItemIndex < 0) return;

            obj.data.splice(removeItemIndex, 1);
        }

        obj.add = (flow: any, position: number = -1) => {
            let flow_id = flow.id();
            let order = new Date().getTime();
            let item = obj.item(flow_id, order);
            if (position < 0)
                obj.data.push(item);
            else
                obj.data.splice(position + 1, 0, item);
        }

        obj.first = () => {
            if (obj.data[0])
                return obj.data[0].id;
            return null;
        }

        obj.next = (flow_id: string) => {
            let idx: number = -2;
            for (let i = 0; i < obj.data.length; i++) {
                if (obj.data[i].id == flow_id) {
                    idx = i;
                    break;
                }
            }
            if (obj.data[idx + 1])
                return obj.data[idx + 1].id;
            return null;
        }

        obj.prev = (flow_id: string) => {
            let idx: number = -2;
            for (let i = 0; i < obj.data.length; i++) {
                if (obj.data[i].id == flow_id) {
                    idx = i;
                    break;
                }
            }
            if (obj.data[idx - 1])
                return obj.data[idx - 1].id;
            return null;
        }

        return obj;
    })(this);
}

export default ComponentDizestCodeflowComponent;