import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.modal.alert');
import { OnInit, Input } from '@angular/core';

@Component({
    selector: 'wiz-component-modal-alert',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/component.modal.alert/view.scss */
.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 420px;
}
.wiz-modal .modal-content {
  width: 100%;
  min-width: 280px;
  max-width: 420px;
}`],
})
export class ComponentModalAlertComponent implements OnInit {
    @Input() model: any;

    public async ngOnInit() {
    }
}

export default ComponentModalAlertComponent;