import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.auth.login');
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-page-auth-login',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/page.auth.login/view.scss */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: wiz-eb;
}

.wrapper {
  background: #3843D0;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #fff;
}

.wrapper.form-success .container h1 {
  transform: translateY(85px);
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 10vh 0;
  height: 100%;
  text-align: center;
}

form {
  padding: 20px 0;
  position: relative;
  z-index: 2;
}

form input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.2);
  width: 250px;
  border-radius: 3px;
  padding: 10px 15px;
  margin: 0 auto 10px auto;
  display: block;
  text-align: center;
  font-size: 18px;
  color: white;
  transition-duration: 0.25s;
}
form input::-webkit-input-placeholder {
  color: white;
}
form input:-moz-placeholder {
  color: white;
  opacity: 1;
}
form input::-moz-placeholder {
  color: white;
  opacity: 1;
}
form input:-ms-input-placeholder {
  color: white;
}

form input:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

form input:focus {
  background-color: white;
  width: 300px;
  color: #3843D0;
}

form button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background-color: white;
  border: 0;
  padding: 10px 15px;
  color: #3843D0;
  border-radius: 3px;
  width: 250px;
  cursor: pointer;
  font-size: 18px;
  transition-duration: 0.25s;
}

form button:hover {
  background-color: #f5f7f9;
}

.bg-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 0;
}

.bg-bubbles li {
  position: absolute;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.15);
  bottom: -160px;
  -webkit-animation: square 25s infinite;
  animation: square 25s infinite;
  transition-timing-function: linear;
  border-radius: 20px;
}

.bg-bubbles li:nth-child(1) {
  left: 10%;
}

.bg-bubbles li:nth-child(2) {
  left: 20%;
  width: 80px;
  height: 80px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 17s;
  animation-duration: 17s;
}

.bg-bubbles li:nth-child(3) {
  left: 25%;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.bg-bubbles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  -webkit-animation-duration: 22s;
  animation-duration: 22s;
  background-color: rgba(255, 255, 255, 0.25);
}

.bg-bubbles li:nth-child(5) {
  left: 70%;
}

.bg-bubbles li:nth-child(6) {
  left: 80%;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  background-color: rgba(255, 255, 255, 0.2);
}

.bg-bubbles li:nth-child(7) {
  left: 32%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
}

.bg-bubbles li:nth-child(8) {
  left: 55%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 15s;
  animation-delay: 15s;
  -webkit-animation-duration: 40s;
  animation-duration: 40s;
}

.bg-bubbles li:nth-child(9) {
  left: 25%;
  width: 10px;
  height: 10px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 40s;
  animation-duration: 40s;
  background-color: rgba(255, 255, 255, 0.3);
}

.bg-bubbles li:nth-child(10) {
  left: 90%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 11s;
  animation-delay: 11s;
}

@-webkit-keyframes square {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-70vh) rotate(600deg);
  }
}
@keyframes square {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-70vh) rotate(600deg);
  }
}`],
})
export class PageAuthLoginComponent implements OnInit {

    public username: string = '';
    public password: string = '';

    constructor(@Inject( Service)         public service: Service,@Inject( ChangeDetectorRef    )         public ref: ChangeDetectorRef    ) { }

    public async alert(message: string) {
        return await this.service.alert.show({
            title: "Error",
            message: message,
            cancel: false,
            action: "Confirm"
        });
    }

    public async ngOnInit() {
        await this.service.init(this);
        await this.service.auth.allow(false, '/main');
    }

    public async login(id, password) {
        let { code, data } = await wiz.call("login", { id, password });
        if (code == 200) {
            location.href = "/";
            return;
        }
        await this.alert(data);
    }
}

export default PageAuthLoginComponent;