import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.dizest.uimode');
import { OnInit, OnDestroy, Input } from '@angular/core';
import { Service } from "src/libs/season/service";
import $ from 'jquery';

@Component({
    selector: 'wiz-component-dizest-uimode',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/component.dizest.uimode/view.scss */
.workspace-tab {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--wiz-color-light);
}
.workspace-tab .workspace-tab-header {
  display: flex;
  background: var(--wiz-color-blue);
  padding: 4px 12px;
  height: 40px;
  align-items: center;
}
.workspace-tab .workspace-tab-header .workspace-tab-header-title {
  color: white;
  font-family: "wiz-eb";
  font-size: 16px;
}
.workspace-tab .workspace-tab-header .close-btn {
  font-size: 16px;
  cursor: pointer;
}
.workspace-tab .workspace-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  flex: 1;
}
.workspace-tab .workspace-tab-body {
  padding: 16px;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: auto;
}
.workspace-tab .workspace-tab-body .card {
  border-radius: 12px;
}

.uimode {
  background: #fff;
}
.uimode .workspace-tab-body {
  overflow: hidden;
}
.uimode .spinner-border {
  width: 48px;
  height: 48px;
  border: 6px solid currentColor;
  border-right-color: transparent;
}
.uimode iframe {
  width: 100%;
  height: 100%;
}

.main-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
}

.maximized {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
}

.code-area {
  background: #fff;
  border-right: 1px solid var(--wiz-color-border);
  display: flex;
  flex-direction: column;
}
.code-area .workspace-tab-header {
  background: var(--wiz-color-blue-light);
  color: var(--wiz-color-blue) !important;
  height: 40px;
  border-bottom: 1px solid var(--wiz-color-border);
}
.code-area .workspace-tab-header .workspace-tab-header-title {
  color: var(--wiz-color-blue) !important;
}
.code-area .code-area-body {
  display: flex;
  width: 100%;
  flex: auto;
}
.code-area .code-area-body .code-container {
  flex: 1;
  border-left: 1px solid var(--wiz-color-border);
  position: relative;
  height: 100%;
}
.code-area .code-area-body .code-container:first-child {
  border-left: none;
}
.code-area .code-area-body .code-container .code-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3001;
}
.code-area .code-area-body .code-container .code-label {
  position: absolute;
  right: 12px;
  bottom: 12px;
  width: 112px;
  text-align: center;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: var(--wiz-color-blue-light);
  color: var(--wiz-color-blue);
  z-index: 3002;
}

.editmode {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
}
.editmode .uimode {
  max-width: 720px;
  flex: 1;
}
.editmode .code-area {
  width: 100%;
  flex: 1;
}`],
})
export class ComponentDizestUimodeComponent implements OnInit, OnDestroy {
    @Input() workflow: any;

    public monaco(lang: string) {
        return {
            value: '',
            language: lang,
            theme: "vs",
            fontSize: 14,
            automaticLayout: true,
            scrollBeyondLastLine: true,
            wordWrap: false,
            roundedSelection: false,
            glyphMargin: false,
            minimap: {
                enabled: false
            }
        }
    }

    public monacopug: any = this.monaco("pug");
    public monacojs: any = this.monaco("javascript");
    public monacoscss: any = this.monaco("scss");
    public monacopy: any = this.monaco("python");

    public data: any;
    public url: any;

    public iframe: boolean = false;
    public iframe_loaded: boolean = false;
    public maximized: boolean = false;
    public edit: boolean = false;
    public enabled: any = { body: true, api: true };

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        if (!this.workflow.flow.selected) return;
        this.data = this.workflow.flow.selected.app().spec();
        this.url = this.workflow.request.uimode.render(this.workflow.flow.selected.id());
        this.workflow.uimodeRender = async () => {
            await this.render();
        };
        
        await this.service.render();
        await this.render();
    }

    public async ngOnDestroy() {
        this.workflow.uimodeRender = null;
        await this.service.render();
    }

    public async render() {
        this.iframe_loaded = false;
        this.iframe = false;
        await this.service.render();

        this.iframe = true;
        await this.service.render();

        $('#uimode-iframe').attr('src', this.url);
        $('#uimode-iframe').on('load', async () => {
            this.iframe_loaded = true;
            await this.service.render();
        });
    }

    public async maximize(maximized: boolean) {
        if (maximized) {
            this.maximized = true;
        } else {
            this.maximized = false;
        }
        await this.service.render();
    }

    public async code(edit: boolean) {
        if (edit) {
            this.edit = true;
            this.enabled = { body: true, api: true };
        } else {
            this.edit = false;
        }
        await this.service.render();
    }

    public async toggle(ctype: string) {
        this.enabled[ctype] = !this.enabled[ctype];
        await this.service.render();
    }

}

export default ComponentDizestUimodeComponent;