import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.dizest.kernel.selector');
import { OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-component-dizest-kernel-selector',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/component.dizest.kernel.selector/view.scss */
.kernel-selector {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
}
.kernel-selector .kernel-select-box {
  background-color: #fff;
  padding: 32px;
  margin: 0 auto;
  border-radius: 24px;
}`],
})
export class ComponentDizestKernelSelectorComponent implements OnInit {
    @Input() list: any = [];
    @Input() kernel: string;
    @Input() returnUrl: string | null = null;
    @Output() onStart = new EventEmitter<number>();

    public selected: any = null;
    public isOpen: boolean = false;

    constructor(@Inject( Service)         public service: Service,@Inject( ChangeDetectorRef    )         public ref: ChangeDetectorRef    ) { }

    public async ngOnInit() {
        for (let i = 0; i < this.list.length; i++) {
            if (this.list[i].name == this.kernel) {
                this.selected = this.list[i];
                break;
            }
        }

        await this.service.render();
    }

    public async select(spec: any) {
        this.kernel = spec.name;
        this.selected = spec;
        await this.toggle()
    }

    public start() {
        this.onStart.emit(this.kernel);
    }

    public async toggle() {
        this.isOpen = !this.isOpen;
        await this.service.render();
    }
}

export default ComponentDizestKernelSelectorComponent;