import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav.navbar');
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-component-nav-navbar',
template: templateSource || '',
    styles: [`

/* file: /opt/kriso-v3/branch/main/build/src/app/component.nav.navbar/view.scss */
header.navbar {
  min-height: auto;
  border-bottom: 1px solid var(--wiz-color-border);
  padding-top: 12px;
  padding-bottom: 12px;
}
header.navbar .container {
  display: block;
}
header.navbar .top-menu {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 4px;
}
header.navbar .top-menu > * {
  margin-left: 12px;
  font-size: 14px;
  color: var(--wiz-color-secondary);
  line-height: 1;
}
header.navbar .top-menu .dropdown .dropdown-toggle {
  font-size: 13px;
  padding: 0 12px;
  height: 21px;
}
header.navbar .main-menu {
  display: flex;
}
header.navbar .main-menu .navbar-brand img {
  height: 40px;
}
header.navbar .main-menu .navbar-menu {
  display: flex;
  flex-direction: column;
  height: auto;
  flex: auto;
  align-items: flex-end;
}
header.navbar .main-menu .navbar-menu .nav-item {
  font-size: 16px;
  min-width: 104px;
  cursor: pointer;
}
header.navbar .main-menu .navbar-menu .nav-item .nav-link {
  padding: 0;
}
header.navbar .main-menu .navbar-menu .nav-item .nav-link .nav-link-title {
  color: fff;
}
header.navbar .main-menu .navbar-menu .nav-item.active {
  margin-left: 6px;
  margin-right: 6px;
}
header.navbar .main-menu .navbar-menu .nav-item.active .nav-link {
  font-weight: 1000;
  font-family: "wiz-eb";
  background: var(--wiz-color-blue);
  border-radius: 8px;
}
header.navbar .main-menu .navbar-menu .nav-item.active .nav-link .nav-link-title {
  color: #fff;
}
header.navbar .main-menu .navbar-menu .nav-items-top {
  display: none;
}
@media (max-width: 768px) {
  header.navbar .main-menu {
    display: block;
  }
  header.navbar .main-menu .navbar-toggler {
    display: inline-block;
    margin-right: 12px;
  }
  header.navbar .main-menu .navbar-brand {
    display: inline-block;
  }
  header.navbar .main-menu .navbar-menu {
    display: block;
  }
  header.navbar .main-menu .navbar-menu .nav-items-top {
    display: block;
    border-top: 1px solid var(--wiz-color-border);
    margin-top: 12px;
    padding-top: 8px;
  }
}`],
})
export class ComponentNavNavbarComponent {
    public isMenuCollapsed: boolean = true;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }
}

export default ComponentNavNavbarComponent;