import '@angular/compiler';
import { ComponentDizestDriveComponent } from './component.dizest.drive/component.dizest.drive.component';
import { PortalDizestEditorTerminalComponent } from './portal.dizest.editor.terminal/portal.dizest.editor.terminal.component';
import { PageAdminPackagesComponent } from './page.admin.packages/page.admin.packages.component';
import { PortalDizestEditorVideoComponent } from './portal.dizest.editor.video/portal.dizest.editor.video.component';
import { PageAdminUsersComponent } from './page.admin.users/page.admin.users.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { ComponentPlaceholderContentComponent } from './component.placeholder.content/component.placeholder.content.component';
import { PortalDizestEditorUimodeComponent } from './portal.dizest.editor.uimode/portal.dizest.editor.uimode.component';
import { ComponentPagenationComponent } from './component.pagenation/component.pagenation.component';
import { PageAdminSystemComponent } from './page.admin.system/page.admin.system.component';
import { ComponentCardStatusComponent } from './component.card.status/component.card.status.component';
import { ComponentModalAlertComponent } from './component.modal.alert/component.modal.alert.component';
import { PageDashboardComponent } from './page.dashboard/page.dashboard.component';
import { PortalDizestEditorImageComponent } from './portal.dizest.editor.image/portal.dizest.editor.image.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { ComponentDizestCronComponent } from './component.dizest.cron/component.dizest.cron.component';
import { PageWorkflowEditorComponent } from './page.workflow.editor/page.workflow.editor.component';
import { PageAdminReleaseComponent } from './page.admin.release/page.admin.release.component';
import { PortalDizestSidebarTimerComponent } from './portal.dizest.sidebar.timer/portal.dizest.sidebar.timer.component';
import { PortalDizestSidebarPackagesComponent } from './portal.dizest.sidebar.packages/portal.dizest.sidebar.packages.component';
import { PageMainComponent } from './page.main/page.main.component';
import { PortalDizestSidebarCodeflowComponent } from './portal.dizest.sidebar.codeflow/portal.dizest.sidebar.codeflow.component';
import { PortalDizestHealthComponent } from './portal.dizest.health/portal.dizest.health.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { ComponentDizestCodeflowComponent } from './component.dizest.codeflow/component.dizest.codeflow.component';
import { PageAuthLoginComponent } from './page.auth.login/page.auth.login.component';
import { PortalDizestSidebarWorkflowinfoComponent } from './portal.dizest.sidebar.workflowinfo/portal.dizest.sidebar.workflowinfo.component';
import { PageAnalysisRequestComponent } from './page.analysis.request/page.analysis.request.component';
import { ComponentDizestAppsComponent } from './component.dizest.apps/component.dizest.apps.component';
import { ComponentDizestDrawflowComponent } from './component.dizest.drawflow/component.dizest.drawflow.component';
import { PortalDizestWidgetWorkflowNodeComponent } from './portal.dizest.widget.workflow.node/portal.dizest.widget.workflow.node.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { ComponentDizestInfoWorkflowComponent } from './component.dizest.info.workflow/component.dizest.info.workflow.component';
import { PortalDizestEditorSettingComponent } from './portal.dizest.editor.setting/portal.dizest.editor.setting.component';
import { ComponentNavNavbarComponent } from './component.nav.navbar/component.nav.navbar.component';
import { PortalDizestDriveComponent } from './portal.dizest.drive/portal.dizest.drive.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalDizestEditorWorkflowComponent } from './portal.dizest.editor.workflow/portal.dizest.editor.workflow.component';
import { PageAdminKernelComponent } from './page.admin.kernel/page.admin.kernel.component';
import { ComponentCardDatasetComponent } from './component.card.dataset/component.card.dataset.component';
import { PageDatasetViewerComponent } from './page.dataset.viewer/page.dataset.viewer.component';
import { PortalSeasonLoadingDefaultComponent } from './portal.season.loading.default/portal.season.loading.default.component';
import { ComponentDizestWorkflowComponent } from './component.dizest.workflow/component.dizest.workflow.component';
import { PortalDizestAppsComponent } from './portal.dizest.apps/portal.dizest.apps.component';
import { PageAnalysisReportComponent } from './page.analysis.report/page.analysis.report.component';
import { ComponentDizestUimodeComponent } from './component.dizest.uimode/component.dizest.uimode.component';
import { ComponentCardWorkflowComponent } from './component.card.workflow/component.card.workflow.component';
import { PageDatasetUploadComponent } from './page.dataset.upload/page.dataset.upload.component';
import { PageDatasetListComponent } from './page.dataset.list/page.dataset.list.component';
import { PageWorkflowComponent } from './page.workflow/page.workflow.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PageExploreComponent } from './page.explore/page.explore.component';
import { PageAdminDatabaseComponent } from './page.admin.database/page.admin.database.component';
import { PageAuthMypageComponent } from './page.auth.mypage/page.auth.mypage.component';
import { PortalDizestCodeflowNoteComponent } from './portal.dizest.codeflow.note/portal.dizest.codeflow.note.component';
import { PageAnalysisReportItemComponent } from './page.analysis.report.item/page.analysis.report.item.component';
import { ComponentDizestVariablesComponent } from './component.dizest.variables/component.dizest.variables.component';
import { PageInstallationComponent } from './page.installation/page.installation.component';
import { LayoutAsideComponent } from './layout.aside/layout.aside.component';
import { ComponentNavAsideComponent } from './component.nav.aside/component.nav.aside.component';
import { ComponentDizestKernelSelectorComponent } from './component.dizest.kernel.selector/component.dizest.kernel.selector.component';
import { PortalDizestStatuscardComponent } from './portal.dizest.statuscard/portal.dizest.statuscard.component';
import { ComponentWorkflowInfoComponent } from './component.workflow.info/component.workflow.info.component';
import { PortalDizestCodeflowAppinfoComponent } from './portal.dizest.codeflow.appinfo/portal.dizest.codeflow.appinfo.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalDizestEditorTextComponent } from './portal.dizest.editor.text/portal.dizest.editor.text.component';
import { PortalDizestEditorNotsupportedComponent } from './portal.dizest.editor.notsupported/portal.dizest.editor.notsupported.component';
import { ComponentDizestInfoAppComponent } from './component.dizest.info.app/component.dizest.info.app.component';
import { MatTreeModule } from '@angular/material/tree';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { SortablejsModule } from "src/libs/ngx-sortablejs";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { NuMonacoEditorModule } from '@ng-util/monaco-editor';

@NgModule({
    declarations: [
        AppComponent,
        ComponentDizestDriveComponent,
        PortalDizestEditorTerminalComponent,
        PageAdminPackagesComponent,
        PortalDizestEditorVideoComponent,
        PageAdminUsersComponent,
        PortalSeasonViewerTreeComponent,
        ComponentPlaceholderContentComponent,
        PortalDizestEditorUimodeComponent,
        ComponentPagenationComponent,
        PageAdminSystemComponent,
        ComponentCardStatusComponent,
        ComponentModalAlertComponent,
        PageDashboardComponent,
        PortalDizestEditorImageComponent,
        LayoutEmptyComponent,
        PortalSeasonStatusbarComponent,
        ComponentDizestCronComponent,
        PageWorkflowEditorComponent,
        PageAdminReleaseComponent,
        PortalDizestSidebarTimerComponent,
        PortalDizestSidebarPackagesComponent,
        PageMainComponent,
        PortalDizestSidebarCodeflowComponent,
        PortalDizestHealthComponent,
        PortalSeasonTabComponent,
        ComponentDizestCodeflowComponent,
        PageAuthLoginComponent,
        PortalDizestSidebarWorkflowinfoComponent,
        PageAnalysisRequestComponent,
        ComponentDizestAppsComponent,
        ComponentDizestDrawflowComponent,
        PortalDizestWidgetWorkflowNodeComponent,
        PortalSeasonPagenationComponent,
        ComponentDizestInfoWorkflowComponent,
        PortalDizestEditorSettingComponent,
        ComponentNavNavbarComponent,
        PortalDizestDriveComponent,
        PortalSeasonAlertComponent,
        PortalDizestEditorWorkflowComponent,
        PageAdminKernelComponent,
        ComponentCardDatasetComponent,
        PageDatasetViewerComponent,
        PortalSeasonLoadingDefaultComponent,
        ComponentDizestWorkflowComponent,
        PortalDizestAppsComponent,
        PageAnalysisReportComponent,
        ComponentDizestUimodeComponent,
        ComponentCardWorkflowComponent,
        PageDatasetUploadComponent,
        PageDatasetListComponent,
        PageWorkflowComponent,
        PortalSeasonUiDropdownComponent,
        PageExploreComponent,
        PageAdminDatabaseComponent,
        PageAuthMypageComponent,
        PortalDizestCodeflowNoteComponent,
        PageAnalysisReportItemComponent,
        ComponentDizestVariablesComponent,
        PageInstallationComponent,
        LayoutAsideComponent,
        ComponentNavAsideComponent,
        ComponentDizestKernelSelectorComponent,
        PortalDizestStatuscardComponent,
        ComponentWorkflowInfoComponent,
        PortalDizestCodeflowAppinfoComponent,
        LayoutNavbarComponent,
        PortalSeasonLoadingHexaComponent,
        PortalSeasonLoadingSeasonComponent,
        PortalDizestEditorTextComponent,
        PortalDizestEditorNotsupportedComponent,
        ComponentDizestInfoAppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        NuMonacoEditorModule.forRoot(),
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.cubeGrid,
            backdropBackgroundColour: "rgba(0,0,0,0.1)",
            primaryColour: "#3843D0",
            secondaryColour: "#3843D0",
            tertiaryColour: "#3843D0",
        }),
                MatTreeModule,
        KeyboardShortcutsModule,
        SortablejsModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }